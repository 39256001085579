:root {
  --white: #eee;
  --black: #141414;
  --color: #3333ff;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.link {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  padding: 0px 3px;
  text-decoration: none;
  position: relative;
  z-index: 1;
}
.link span,
.link i {
  position: relative;
}
.link i {
  margin-left: 0.5em;
}
.link .color {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background-color: var(--color);
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: -0.65em;
}
.wrapper{
  width: fit-content;
}