.Cookie {
    background-color: #1e2222;
    width: 80%;
    height: fit-content;
    position: fixed;
    // top: 35vh;
    bottom: 2vh;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    box-sizing: border-box;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;

    .desc {
        margin-top: 5px;
        font-size: 36px;
        margin-left: 14px;
        font-weight: 500;
        line-height: 34px;
    }

    .P {
        margin-left: 14px;
        font-weight: 500;
        font-size: 16px;
        margin-top: 20px;
    }

    hr {
        margin-top: 10px;
        margin-left: 14px;
        height: 2px;
    }

    .buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        font-size: 18px;

        :nth-child(1) {
            text-decoration: underline;
            cursor: pointer;
        }

        :nth-child(2) {
            // background: linear-gradient(102.57deg, #DC0916 0%, #F41FD6 100%);
            background-color: #AB0F0C;
            padding: 20px;
            border-radius: 50px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .Cookie {
        // top: 2vh;

        .desc {
            font-size: 24px;
            line-height: 28px;
        }

        .P {
            font-size: 12px;
            margin-top: 10px;
        }

        hr {
            margin-top: 6px;
        }

        .buttons {
            margin-top: 10px;
            gap: 16px;
            font-size: 10px;
    
            :nth-child(2) {
                padding: 10px 15px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .Cookie {
        // top: 2vh;
        padding: 10px 20px;

        .companyLogo{
            height: 40px;

            img{
                height: 100%;
            }
        }

        .desc {
            font-size: 14px;
            line-height: 14px;
        }

        .P {
            font-size: 10px;
            margin-top: 5px;
        }

        hr {
            margin-top: 6px;
        }

        .buttons {
            margin-top: 10px;
            gap: 16px;
            font-size: 10px;
        }
    }
}