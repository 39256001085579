.MesaDeAyuda {

    .banner {
        height: 80vh;
        width: 100%;
        position: relative;

        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -2;
        }

        .shadow {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: linear-gradient(to right, rgba(50, 50, 50, 1), rgba(50, 50, 50, 0));
        }

        .bannerTextContainer {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .bannerText {
                width: 80%;
                margin: auto;
                color: white;
                display: flex;
                flex-direction: column;
                gap: 10px;

                :nth-child(1) {
                    font-size: 24px;
                    font-weight: 500;
                    color: #b8babc;
                }

                :nth-child(2) {
                    font-size: 56px;
                    font-weight: 700;
                }

                button {
                    padding: 10px 20px;
                    border: 1px solid #AB0F0C;
                    border-radius: 9999px;
                    background-color: transparent;
                    outline: none;
                    width: fit-content;
                    font-size: 20px;
                    color: #AB0F0C;
                    font-weight: 700;
                }
            }
        }
    }

    .SecondComponent {
        width: 100%;
        padding: 40px 0px;

        .container {
            width: 80%;
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .mainHeading {
                width: 80%;
                font-size: 56px;
                font-weight: 600;
            }

            .subHeading {
                width: 60%;
                font-size: 22px;
            }

            .gridContainer {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                gap: 20px;

                .box {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .box-index {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .box-heading {
                        font-size: 26px;
                        font-weight: 500;
                    }

                    .box-details {
                        font-size: 22px;
                    }
                }
            }

            @media screen and (max-width: 1680px) {
                .mainHeading {
                    font-size: 52px;
                }

                .subHeading {
                    font-size: 18px;
                }

                .gridContainer {
                    .box {
                        .box-heading {
                            font-size: 24px;
                        }

                        .box-details {
                            font-size: 16px;
                        }
                    }
                }
            }

            @media screen and (max-width: 1440px) {
                .mainHeading {
                    font-size: 50px;
                }

                .subHeading {
                    font-size: 17px;
                }

                .gridContainer {
                    .box {
                        .box-heading {
                            font-size: 22px;
                        }
                    }
                }
            }

            @media screen and (max-width: 1280px) {
                .mainHeading {
                    font-size: 46px;
                }
            }

            @media screen and (max-width: 1200px) {
                .mainHeading {
                    font-size: 40px;
                }

                .subHeading {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1024px) {
                .mainHeading {
                    font-size: 36px;
                }

                .subHeading {
                    font-size: 14px;
                }

                .gridContainer {
                    .box {
                        .box-heading {
                            font-size: 18px;
                        }

                        .box-details {
                            font-size: 14px;
                        }
                    }
                }
            }

            @media screen and (max-width: 912px) {
                .mainHeading {
                    font-size: 32px;
                }

                .gridContainer {
                    .box {
                        .box-heading {
                            font-size: 16px;
                        }
                    }
                }
            }

            @media screen and (max-width: 820px) {
                .mainHeading {
                    font-size: 30px;
                }
            }

            @media screen and (max-width: 767px) {
                .mainHeading {
                    font-size: 27px;
                }
            }

            @media screen and (max-width: 650px) {
                .mainHeading {
                    font-size: 22px;
                    font-weight: 500;
                }
            }
        }
    }

    .ThirdComponent {
        width: 100%;
        padding: 40px 0px;
        background-color: #f2f3f8;

        .container {
            width: 80%;
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            text-align: center;

            .mainHeading {
                font-size: 56px;
                font-weight: 600;
            }

            .subHeading {
                font-size: 22px;
            }

            .buttons {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                flex-wrap: wrap;

                button {
                    background-color: transparent;
                    outline: none;
                    border: none;
                    border-radius: 8px;
                    padding: 10px 30px;
                    font-weight: 600;
                    transition: all 500ms;
                    cursor: pointer;
                }

                .normalButton {
                    border: 1px solid black;
                    color: black;
                }

                .hoveredButton {
                    border: 1px solid #AB0F0C;
                    color: white;
                    background-color: #AB0F0C;
                }
            }

            @media screen and (max-width: 1680px) {
                .mainHeading {
                    font-size: 52px;
                }
                .subHeading {
                    font-size: 16px;
                }
            }
            @media screen and (max-width: 1440px) {
                .mainHeading {
                    font-size: 50px;
                }
            }
            @media screen and (max-width: 1280px) {
                .mainHeading {
                    font-size: 46px;
                }
            }
            @media screen and (max-width: 1200px) {
                .mainHeading {
                    font-size: 40px;
                }
            }
            @media screen and (max-width: 1024px) {
                .mainHeading {
                    font-size: 36px;
                }
            }
            @media screen and (max-width: 912px) {
                .mainHeading {
                    font-size: 32px;
                }
            }
            @media screen and (max-width: 820px) {
                .mainHeading {
                    font-size: 30px;
                }
            }
            @media screen and (max-width: 767px) {
                .mainHeading {
                    font-size: 27px;
                }
            }
            @media screen and (max-width: 767px) {
                .mainHeading {
                    font-size: 22px;
                    font-weight: 500;
                }
            }
        }
    }

}

@media screen and (max-width: 1000px) {
    .MesaDeAyuda {
        .banner {
            .bannerTextContainer {
                .bannerText {
                    width: 90%;

                    :nth-child(2) {
                        font-size: 26px;
                    }
                }
            }
        }

        .SecondComponent {
            .container {
                width: 90%;

                .mainHeading {
                    width: 100%;
                }

                .subHeading {
                    width: 100%;
                }

                .gridContainer {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }
        }

        .ThirdComponent{
            .container{
                width: 90%;
            }
        }
    }
}