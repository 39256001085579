.MiniTractor {
    width: 100%;
    padding: 40px 0px;

    .container {
        width: 80%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .main-heading {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
        }

        .sub-heading {
            font-size: 18px;
            max-width: 600px;
            width: 100%;
            text-align: center;
            margin: 0px auto;
        }

        .details-box {
            width: 100%;
            display: flex;
            gap: 20px;
            align-items: center;

            .left-details {
                width: 50%;
                padding: 20px;
                border-radius: 20px;
                // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                display: flex;
                flex-direction: column;
                gap: 20px;

                .left-heading {
                    font-size: 26px;
                    font-weight: bold;
                }

                .left-paragraph {
                    font-size: 16px;
                }

                button{
                    width: fit-content;
                    border: none;
                    text-align: center;
                    padding: 10px 20px;
                    background-color: #AB0F0C;
                    color: white;
                    font-weight: 600;
                    border-radius: 8px;
                }
            }

            .right-details {
                width: 50%;
                padding: 20px;
                // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .MiniTractor{
        .container{
            width: 90%;

            .details-box{
                flex-direction: column;

                .left-details{
                    width: 100%;
                }

                .right-details{
                    width: 100%;
                }
            }
        }
    }
}