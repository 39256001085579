.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: start;
}

.hero {
  padding-right: 40px;
}

.hero h1 {
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #2d2d2d;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0 0 60px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  font-size: 18px;
  color: #2d2d2d;
}

.check-icon {
  color: #12b886;
}

.trusted-section {
  margin-top: 80px;
}

.trusted-section h2 {
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
  text-align: center;
}

.logo-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  opacity: 0.7;
}

.logo-grid img {
  height: 30px;
  object-fit: contain;
}

.signup-form {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.google-button {
  width: 100%;
  padding: 12px;
  background: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.divider {
  text-align: center;
  margin: 20px 0;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #2d2d2d;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.required {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
}

.terms-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.terms-checkbox input[type="checkbox"] {
  margin: 0;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background: #aa0f0b;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.login-link a {
  color: #4285f4;
  text-decoration: none;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }

  .hero {
    padding-right: 0;
  }
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .form-row .form-group {
    margin-bottom: 20px;
  }
}

