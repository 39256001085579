.single-property-container {
  width: 100%;
  height: 100%;
  padding: 40px 80px;

  @media screen and (max-width: 1000px) {
    padding: 20px 15px;
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
  }

  span {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    // border-bottom: 1px solid gray;
    cursor: pointer;
  }

  .single-property {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin: 20px auto;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }

    .left-section {
      width: 50%;
      text-align: right;
      position: relative;

      @media screen and (max-width: 1100px) {
        width: 100%;
      }

      .image-section {
        width: 99%;
        height: fit-content;
        position: relative;
        height: 480px;

        img {
          width: 99%;
          height: 100%;
          border-radius: 10px;
          transition: all 500ms;
          object-fit: cover;
        }

        .TractoInfo {
          // display: none;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: white;
          transition: all 500ms;

          &:hover {
            div {
              visibility: visible;
              opacity: 1;
            }
          }

          div {
            transition: linear 0.3s;
            opacity: 0;
            visibility: hidden;
            padding: 20px 20px;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 999px;
          }
        }
      }
    }

    .right-section {
      width: 50%;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      gap: 3.5px;
      transition: all 500ms;

      @media screen and (max-width: 1100px) {
        width: 100%;
      }

      @media screen and (max-width: 480px) {
        gap: 0;
      }

      img {
        width: 48%;
        margin: 1px 3.5px;
        border-radius: 10px;
        height: 200px;
        object-fit: cover;

        @media screen and (max-width: 1100px) {
          height: auto;
        }

        @media screen and (max-width: 480px) {
          margin: 0;
        }
      }

      div {
        width: 48%;
        margin: 1px 3.5px;
        // border-radius: 10px;
        height: 200px;
        position: relative;

        @media screen and (max-width: 1100px) {
          height: auto;
        }

        @media screen and (max-width: 480px) {
          margin: 1px;
        }

        img {
          width: 100%;
          // margin: 1px 3.5px;
          border-radius: 10px;
          height: 100%;

        }

        .text-container {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .Attatchment {
            transition: linear 0.3s;
            opacity: 0;
            visibility: hidden;
            width: fit-content;
            height: fit-content;
            padding: 20px 20px;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 999px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media screen and (max-width: 440px) {
              padding: 10px;

              h4 {
                font-size: 14px;
              }
            }
          }

        }
      }
    }
  }

  .extra-details {
    margin-top: 50px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .extra-left {
      width: 55%;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      .left-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .left-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 30px 0px;
        border-bottom: 1px solid lightgray;

        .tab {
          width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 5px;
          border: 1px solid lightgray;
          padding: 9px;
          border-radius: 10px;

          p {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            padding: 6px 0px;
          }

          // &:first-child {
          //   padding: 15px 0px;
          //   gap: 0px;
          // }
        }
      }

      .left-text {
        padding: 30px 0px;
        border-bottom: 1px solid lightgray;

        .text-box {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 0px;

          h4 {
            font-size: 15px;
            font-weight: 600;
            line-height: 25px;
          }

          p {
            font-size: 13px;
          }
        }
      }

      .left-about {
        padding: 30px 0px;

        h3 {
          padding: 5px 0px;
          font-size: 20px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .extra-right {
      width: 45%;
      padding: 10px;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      .extra-right-box {
        margin: 0px auto;
        width: 70%;
        height: 70vh;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 20px 20px;
        z-index: 2;
        box-shadow: rgba(51, 51, 51, 0.2) 0px 4px 10px,
          rgba(51, 51, 51, 0.1) 0px 0px 4px;

        @media screen and (max-width: 700px) {
          width: 100%;
        }

        .right-price {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 19px;
            font-weight: 600;
            padding: 5px 0px;

            span {
              font-size: 16px;
              font-weight: 500;
            }
          }

          .star {
            display: flex;
            align-items: center;
            gap: 5px;

            p {
              font-size: 13px;
            }

            span {
              font-size: 12px;
            }
          }
        }

        .right-date {
          margin-top: 10px;
          padding: 18px 0px;
          border-radius: 5px;
          text-align: center;

          // border: 1px solid gray;
          .setTime {
            position: relative;
            z-index: 8;

            .check-in-out {
              font-size: 15px;
              padding-bottom: 10px;
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                font-size: 16px;
                font-weight: 500;
              }
            }

            .sp-cal {
              position: absolute;
              top: 100%;
              right: 0px;
              transition: linear 0.3s;

              .singlecal {
                border-bottom: none;
                width: 400px;
                // height: 50vh;
                background-color: white;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px,
                  rgba(0, 0, 0, 0.07) 0px 0px 0px 1px !important;

                // .nice-dates-day_date{
                //   border-bottom: none;
                // }
                .nice-dates-day {
                  &::before {

                    background-color: #AB0F0C;
                  }
                }

                .singlebtn {
                  text-align: center;

                  button {
                    width: 110px;
                    margin: 10px auto;
                    padding: 10px 0px;
                    background-color: #AB0F0C;
                    color: white;
                    border: none;
                    outline: none;
                    border-radius: 3px;
                  }
                }
              }
            }

            .hide-sp-cal {
              visibility: hidden;
              opacity: 0;
            }
          }

          .guests {
            width: 91%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            gap: 10px;
            margin: 10px auto;
            padding: 9px 10px;
            border-radius: 5px;
            border: 1px solid gray;
            z-index: 1;

            .select-guest {
              position: absolute;
              top: 105%;
              left: 50%;
              transform: translateX(-50%);
              background-color: rgb(255, 255, 255);
              width: 100%;
              height: fit-content;
              border-radius: 10px;
              padding: 20px 15px;
              transition: linear 0.3s;
              box-shadow: rgba(51, 51, 51, 0.2) 0px 4px 10px,rgba(51, 51, 51, 0.1) 0px 0px 4px;

              ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0px;

                li {
                  list-style: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  font-size: 14px;
                  font-weight: 600;

                  span {
                    font-size: 12px;
                    padding: 0px;
                    border: none;
                    color: #5e5e5e;
                    font-weight: 400;
                  }
                }

                .inc-desc {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .desc {
                    font-size: 13px;
                    // padding: 5px;
                    cursor: pointer;

                    .fa-solid {
                      border-radius: 999px;
                      padding: 8px 8px;
                      color: #5e5e5e;
                      border: 1px solid lightgray;
                    }
                  }

                  .number {
                    font-size: 18px;
                  }

                  .inc {
                    cursor: pointer;

                    .fa-solid {
                      padding: 6px 8px;
                      border-radius: 999px;
                      color: #5e5e5e;
                      border: 1px solid lightgray;
                    }
                  }
                }
              }

              .bottom-para {
                padding-top: 15px;
                font-size: 10px;
                text-align: left;
              }

              .exit {
                padding-top: 10px;
                text-align: right;
                width: 100%;

                p {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }

            .hide-guest {
              visibility: hidden;
              opacity: 0;
            }
          }

          .sp-button {
            display: block;
            width: 90%;
            padding: 10px 0px;
            margin: 15px auto 1px auto;
            outline: none;
            border: none;
            border-radius: 5px;
            color: white;
            font-weight: 500;
            background-color: #0078db;
            cursor: pointer;
          }

          .d-charge {
            font-size: 12px;
            font-weight: 400;
            border: none;
          }
        }

        .price-cal {
          width: 90%;
          margin: 0px auto;

          .price-one {
            padding: 8px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              padding: 1px 0px;
              font-size: 15px;
              font-weight: 500;

              &:first-child {
                border-bottom: 1px solid gray;
              }
            }
          }

          .price-total {
            border-top: 1px solid lightgray;
            margin-top: 10px;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-weight: 500;
            }
          }
        }
      }

      .extra-style {
        box-shadow: none;
      }
    }
  }

  .map {
    width: 100%;
    padding: 20px 0;
    margin: 10px auto;
    text-align: center;

    h3 {
      font-weight: 600;
    }

    p {
      padding: 10px 0px;
      font-size: 14px;
      margin-bottom: 10px;
    }

    iframe {
      border: 2px solid lightgray;
      border-radius: 10px;
      margin: 0px auto;
    }
  }
}

// -------------------- Responsive Design -------------------------

// @media screen and (max-width: 480px) {
//   .single-property-container {
//     // flex-direction: column;
//     padding: 20px 15px;

//     .single-property {
//       flex-direction: column;

//       .left-section {
//         width: 100%;
//       }

//       .right-section {
//         width: 100%;
//         // text-align: center;

//         img {
//           width: 48%;
//         }
//       }
//     }

//     .extra-details {
//       flex-direction: column;

//       .extra-left {
//         width: 100%;

//         .left-tab {
//           width: 100%;
//           gap: 10px;

//           .tab {
//             p {
//               width: 70%;
//             }
//           }
//         }
//       }

//       .extra-right {
//         width: 100%;

//         .extra-right-box {
//           width: 100%;
//           height: 56vh;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 380px) {
//   .single-property-container {
//     .single-property {
//       .right-section {
//         img {
//           width: 45%;
//         }
//       }
//     }

//     .extra-details {
//       .extra-right {
//         .extra-right-box {
//           height: 65vh;
//         }
//       }
//     }
//   }
// }