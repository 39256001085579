.DenuDelTractor{
    width: 100%;
    position: relative;
    padding-top: 60vh;

    .TopImageComponent{
        position: absolute;
        top: 0;
        right: 0;
        height: 80vh;
        width: 100%;
        z-index: -1;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    .FirstComponent{
        // margin-top: 60vh;
        margin-left: auto;
        margin-right: auto;
        width: 80vw;
        background-color: white;
        display: flex;
        align-items: center;
        padding: 40px;
        gap: 20px;
        // box-shadow: 0px 2px 2px gray;
        box-shadow: 0 2px 4px 0 gray;
        border-radius: 10px;

        .left{
            flex: 6;

            .heading{
                font-weight: 800;
                font-size: 26px;
            }

            .sub-heading{
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
            }

            .para1{
                margin: 12px 0px;
                color: gray;
                font-size: 14px;
                width: 80%;
            }

            .para2{
                color: gray;
                font-size: 14px;
                width: 80%;
            }
        }

        .right{
            flex: 4;
            display: flex;
            flex-direction: column;
            
            .button{
                background-color: #AB0F0C;
                padding: 8px 16px;
                width: fit-content;
                align-self: flex-end;
                color: white;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                margin-bottom: 40px;
            }

            .buttom{
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 14px;
                
                .Form{
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .formLabel{
                        color: gray;
                    }

                    .formValue{
                        font-weight: 600;
                    }
                }
            }
        }
    }
    
    .SeconsComponent{
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        width: 80vw;
        padding: 40px;
        box-shadow: 0 2px 4px 0 gray;
        border-radius: 10px;
        display: flex;
        gap: 30px;

        .left{
            flex: 7;

            .heading{
                font-weight: 600;
                font-size: 32px;
                margin-bottom: 15px;
            }

            .para1{
                color: gray;
                font-size: 18px;
                margin-bottom: 15px;
            }

            .keypoints{
                margin-bottom: 15px;

                .keypoint{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-weight: 600;

                    img{
                        width: 20px;
                    }
                }
            }
        }

        .right{
            flex: 3;

            img{
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
                height: 100%;
            }
        }
    }

    .ThirdComponent{
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        width: 80vw;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgb(245, 241, 241);
        border-radius: 20px;
        margin-bottom: 40px;

        .heading{
            font-weight: 800;
            font-size: 32px;
        }

        .processBox{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 40px 0px;

            .box{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .circe{
                    height: 40px;
                    width: 40px;
                    background-color: #AB0F0C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-weight: 600;
                    border-radius: 50%;
                    margin-bottom: 5px;
                }

                .box-heading{
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 5px;
                    text-align: center;
                }

                .para{
                    color: gray;
                    font-size: 14px;
                    line-height: 20px;
                    width: 80%;
                    text-align: center;
                }
            }
        }

        .feedBackBox{
            margin-top: 30px;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;

            .left{
                flex: 3;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background: linear-gradient(136deg, #FF29F6 0%, #FAF462 100%);
                min-height: 300px;
                max-height: fit-content;
                border-radius: 20px 0px 0px 20px;

                .image{
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;

                    img{
                        width: 130px;
                        height: 130px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
            }

            .right{
                flex: 7;
                border-radius: 0px 20px 20px 0px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 300px;
                max-height: fit-content;

                p{
                    width: 60%;
                    font-weight: 800;
                }

                .mobile-details{
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .DenuDelTractor{

        .FirstComponent{
            width: 80%;
        }

        .SeconsComponent{
            width: 80%;
        }

        .ThirdComponent{
            width: 80%;
        }
    }
}

@media screen and (max-width: 900px){
    .DenuDelTractor{

        .FirstComponent{
            width: 90%;
        }

        .SeconsComponent{
            width: 90%;
        }

        .ThirdComponent{
            width: 90%;

            .feedBackBox{
                width: 90%;

                .right{

                    p{
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px){
    .DenuDelTractor{

        .ThirdComponent{

            .processBox{
                flex-direction: column;
                gap: 16px;
            }
        }
    }
}

@media screen and (max-width: 600px){
    .DenuDelTractor{

        .FirstComponent{
            flex-direction: column;

            .right{

                .button{
                    align-self: flex-start;
                }
            }
        }

        .SeconsComponent{
            flex-direction: column;
        }

        .ThirdComponent{

            .feedBackBox{

                .left{
                    display: none;
                }

                .right{
                    border-radius: 20px;
                    flex-direction: column;
                    gap: 40px;
                    padding: 16px;

                    .mobile-details{
                        display: flex;
                        align-self: flex-start;
                        margin-left: 30px;
                        gap: 20px;

                        .basic-details{
                            font-weight: 600;
                        }

                        img{
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}