    .properties-section{
        width: 24%;
        text-align: center;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        img{
            border-radius: 10px;
            width: 285px;
            height: 270px;
            object-fit: cover;
            transition: linear 0.2s;
            cursor: pointer;
            margin-bottom: 10px;
            &:hover{
                // transform: scale(1.1);
                opacity: 0.9;
            }
            
        }
       
        .title{
            text-align: left;
            padding-left: 10px;
            cursor: pointer;
            h4{
                font-weight: 600;
                font-size: 17px;
            }
        }
        .para{
            padding: 2px 0px;
            text-align: left;
            font-size: 12.5px;
            font-weight: 500;
            padding-left: 10px;
            cursor: pointer;
        }
        .price{
            text-align: left;
            font-weight: 500;
            font-size: 15px;
            padding-left: 10px;
            cursor: pointer;
        }
    }

// --------------- Responsive Design -----------------

@media screen and (max-width: 480px){
    .properties-section{
        width: 100%;
        // overflow: hidden;
        img{
            width: 100%;
        }
    }
}