@keyframes insideBoxMove {
    0% {
        left: -100%;
        transition: all 500ms;
    }

    100% {
        left: 0px;
        transition: all 500ms;
    }
}

.mainContainer {
    width: 100%;

    .BannerComponent {
        width: 100%;
        height: 80vh;
        display: flex;

        .leftContainer {
            width: 50%;
            height: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .shadow {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: linear-gradient(to right, rgba(50, 50, 50, 1), rgba(50, 50, 50, 0));
                display: none;
            }

            .textContainer {
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 12px;

                p {
                    font-weight: 600;
                }

                .subHeading {
                    font-size: 24px;
                    color: gray;
                }

                .mainHeading {
                    font-size: 53px;
                }

                .paragraphOne {
                    font-size: 24px;
                }

                .emailGetStartedComponent {
                    border: 1px solid #f2f3f8;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 9999px;
                    overflow: hidden;
                    width: fit-content;

                    input {
                        width: 200px;
                        background: transparent;
                        outline: none;
                        border: none;
                    }

                    button {
                        padding: 10px;
                        background: transparent;
                        outline: none;
                        border: none;
                        font-weight: 600;
                        font-size: 20px;
                        border-left: 1px solid #f2f3f8;
                        color: #f2f3f8;
                        cursor: pointer;
                    }
                }

                @media screen and (max-width: 767px) {
                    .subHeading {
                        font-size: 20px;
                    }

                    .mainHeading {
                        font-size: 40px;
                    }

                    .paragraphOne {
                        font-size: 20px;
                    }
                }
            }
        }

        .rightContainer {
            width: 50%;
            height: 100%;
        }
    }

    .SecondComponent {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #f2f3f8;

        .mainHeading {
            width: 80%;
            margin: auto;
            text-align: center;
            font-weight: 700;
            font-size: 56px;
        }



        .container {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            display: flex;
            gap: 20px;

            .box {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .boxTop {
                    display: flex;
                    gap: 10px;

                    .boxTopRight {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-size: 26px;
                        font-weight: 600;
                    }

                    @media screen and (max-width: 479px) {
                        .boxTopRight {
                            font-size: 20px;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        .boxTopRight {
                            font-size: 24px;
                        }
                    }
                }

                .boxBottom {
                    padding: 10px;
                    font-size: 22px;
                }

                @media screen and (max-width: 500px) {
                    .boxTop {
                        flex-direction: column;
                        text-align: center;

                        video {
                            margin: auto;
                        }
                    }

                    .box-bottom {
                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .ThirdCpmponent {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        background-color: #f2f3f8;

        .container {
            width: 80%;
            margin: auto;
            display: flex;
            align-items: center;
            gap: 20px;

            .left-container {
                width: 50%;

                .mainHeading {
                    font-size: 56px;
                    font-weight: 700;
                }

                .paragraphsContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;
                    margin: auto;
                    margin-top: 20px;

                    .box {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .box-top {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-weight: 700;
                            font-size: 24px;
                        }

                        .box-middle {
                            width: calc(100% - 40px);
                            height: 2px;
                            background-color: gray;
                            margin-left: 30px;
                            border-radius: 999px;
                            position: relative;
                            overflow: hidden;

                            .inside-box-middle {
                                width: 100%;
                                height: 100%;
                                background-color: #AB0F0C;
                                border-radius: 999px;
                                position: absolute;
                                left: -100%;
                                top: 0px;
                                animation: insideBoxMove 3s linear 0s infinite;
                            }
                        }

                        .box-bottom {
                            padding-left: 40px;
                        }
                    }
                }
            }

            .right-container {
                width: 50%;
                height: fit-content;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 90%;
                    margin: auto;
                    height: 90vh;
                    object-fit: cover;
                }
            }
        }
    }

    .FourthComponent {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f2f3f8;

        .container {
            width: 80%;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h1 {
                text-align: center;
                font-size: 56px;
                font-weight: 700;
            }

            .box {
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                position: relative;

                &::before{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: '';
                    width: 100%;
                    aspect-ratio: 1/1;
                    border-radius: 999px;
                    z-index: -1;
                    background: radial-gradient(circle, 
                                rgba(171, 15, 12, 0.5), 
                                rgba(171, 15, 12, 0.3) 20%, 
                                rgba(171, 15, 12, 0) 40%,
                                rgba(171, 15, 12, 0) 50%,
                                rgba(171, 15, 12, 0) 80%,
                                rgba(171, 15, 12, 0) 100%);
                }

                .box-container {
                    width: 100%;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    background-color: #f2f3f8;
                    display: grid;
                    z-index: 1;
                    grid-template-columns: repeat(7, minmax(0, 1fr));
                    gap: 20px;

                    img {
                        width: 100%;
                        height: 160px;
                        border-radius: 4px;
                        object-fit: cover;
                    }
                }
            }

            .paragraphs-container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .mainContainer {
        .BannerComponent {
            position: relative;
            color: white;

            .leftContainer {
                width: 100%;
                background-color: transparent;

                .shadow {
                    display: block;
                }

                .textContainer {
                    width: 90%;
                }
            }

            .rightContainer {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                z-index: -2;
            }
        }

        .SecondComponent {
            .mainHeading {
                width: 90%;
            }

            .container {
                width: 90%;
                flex-direction: column;

                .box {
                    width: 100%;
                }
            }
        }

        .ThirdCpmponent {
            .container {
                width: 90%;
                flex-direction: column;

                .left-container {
                    width: 90%;
                }

                .right-container {
                    width: 100%;
                }
            }
        }

        .FourthComponent {
            .container {
                width: 90%;

                .box{

                    .box-container{
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .mainContainer {
        .SecondComponent {
            .mainHeading {
                font-size: 40px;
            }
        }

        .ThirdCpmponent {
            .container {
                .left-container {
                    .mainHeading {
                        font-size: 40px;
                    }
                }
            }
        }

        .FourthComponent {
            .container {
                h1 {
                    font-size: 40px;
                }
            }
        }
    }
}