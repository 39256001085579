.wrapper {
  background: linear-gradient(180deg, #0A0A1A 0%, #1A1A2A 100%);
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.banner {
  background: white;
  border-radius: 2rem;
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4rem;
  position: relative;
  overflow: hidden;
}

.content {
  max-width: 540px;
}

.title {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 2.5rem;
  color: #000;
}

.buttonWrapper {
  display: inline-flex;
  padding: 0.5rem;
  background: #d1ff3f;
  border-radius: 2rem;
  transition: all 0.2s ease;
}

.buttonWrapper:hover {
  transform: translateY(-2px);
  background: #c4ff3e;
}

.button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  color: #000;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 1rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #000;
}

.icon {
  color: #d1ff3f;
  width: 16px;
  height: 16px;
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 8px 24px rgba(0, 0, 255, 0.1));
}

@media (max-width: 768px) {
  .banner {
    grid-template-columns: 1fr;
    padding: 2rem;
    gap: 2rem;
    text-align: center;
  }
.buttonGroup{
  align-items: center;
  justify-content: center;
}
  .content {
    max-width: 100%;
  }

  .title {
    font-size: 2.5rem;
  }

  .buttonWrapper {
    width: 100%;
    justify-content: center;
  }

  .imageWrapper {
    order: -1;
  }
}

.buttonGroup {
    display: flex;
  }
  
  .iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CCFF00;
    padding: 0.75rem;
    border-radius: 12px 12px 9px 12px;
    transition: background-color 0.3s;
  }
  
  .ctaButton {
    background-color: #CCFF00;
    color: black;
    padding: 0.75rem 1.5rem;
    border-radius: 12px 12px 10px 12px;
    text-decoration: none;
    transition: background-color 0.3s;
    margin: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .ctaButton:hover {
    background-color: rgba(204, 255, 0, 0.9);
  }
  
  .arrow {
    transition: transform 0.3s;
  }
  
  .ctaButton:hover .arrow {
    transform: translate(2px, 2px);
  }