.properties-container {
  width: 100%;
  height: 100%;
  padding: 20px 20px 50px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  // overflow: hidden;

  .container {
    width: 80%;
    margin: auto;

    .box {
      width: 100%;
      display: flex;
      gap: 20px;
      padding: 10px;
      // border: 1px solid #AB0F0C;
      box-shadow: 1px 1px 5px rgba(#AB0F0C, 0.3);
      border-radius: 10px;
      // background-color: rgba(#AB0F0C, 0.04);

      .box-left {
        width: 285px;
        height: 270px;
        position: relative;

        .verified {
          padding: 6px 10px;
          border-radius: 6px;
          color: white;
          position: absolute;
          top: 10px;
          left: 14px;
          z-index: 5;
          width: fit;
        }

        .verified-bg {
          background-color: rgba(#00CC00, 0.5);
        }

        .unverified-bg {
          background-color: rgba(#FF0000, 0.5);
        }

        .profile-bottom-section {
          position: absolute;
          bottom: 10px;
          width: 96%;
          left: 50%;
          translate: -50%;
          z-index: 5;

          .top-section {
            display: flex;
            gap: 6px;

            .top-left-section {
              width: 50px;
              height: 50px;
              position: relative;

              .profile {
                width: 46px;
                height: 46px;
                border-radius: 999px;
              }

              .profile-badge {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 0;
                bottom: 0;
              }
            }

            .top-right-section {
              display: flex;
              flex-direction: column;
              gap: 4px;
              color: white;

              .deale-rname {
                font-weight: 600;
                font-size: 20px;
              }

              .total-services {
                font-size: 14px;
              }
            }
          }
        }

        img {
          border-radius: 10px;
          width: 285px;
          height: 270px;
          object-fit: cover;
          z-index: 1;
        }
      }

      .box-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;

        .box-right-top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .box-right-top-left {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;

            .store-name {
              font-size: 18px;
              font-weight: 700;
            }

            .rent-price {
              font-size: 14px;
              color: #AB0F0C;
              font-weight: 600;
            }
          }
        }

        .paragraphs-container {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .mainHeading {
            font-size: 16px;
            font-weight: 500;
          }

          .middleHeading {
            font-size: 14px;
            color: #747474;
          }

          .thirdHeading {
            font-weight: 500;
            font-size: 14px;
            color: #747474;

            :nth-child(1) {
              font-size: 16px;
              color: black;
            }
          }
        }

        .possesion-section {
          width: 100%;
          display: flex;
          gap: 30px;
          flex-wrap: wrap;

          .box-possesion {
            display: flex;
            align-items: center;
            gap: 8px;

            .location {
              display: flex;
              flex-direction: column;
              gap: 6px;
              align-items: center;
              justify-content: center;

              .location-heading {
                font-size: 16px;
                color: #747474;
                font-weight: 600;
              }

              .location-value {
                font-weight: 500;
                font-size: 14px;
              }
            }
          }

          .rating {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-items: center;
            justify-content: center;

            .rating-heading {
              font-size: 16px;
              color: #747474;
              font-weight: 600;
            }

            .rating-value {
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .distance-section {
          display: flex;
          align-items: center;
          gap: 16px;
          font-size: 16px;

          .left-most {
            color: #747474;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 6px;
          }

          .sliding-section {
            display: flex;
            align-items: center;
            gap: 10px;

            .slide {
              padding: 6px 6px;
              border-radius: 4px;
              background-color: rgba(gray, 0.1);
              display: flex;
              align-items: center;
              gap: 6px;

              :nth-child(1) {
                font-weight: 500;
                color: #747474;
              }
            }
          }
        }

        .buttom-section {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;

          .bottom-section-left {
            display: flex;
            align-items: center;
            gap: 8px;

            .left-most {
              height: 30px;
              width: 30px;
              border-radius: 4px;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #AB0F0C;
              font-size: 18px;
              font-weight: 600;
            }

            .beside-left {
              .long-heading {
                font-weight: 600;
              }

              .short-heading {
                font-size: 14px;
                font-weight: 500;
                color: rgba(gray, 0.8);
              }
            }
          }

          .buttons-section {
            display: flex;
            gap: 20px;
            font-size: 20px;

            .button1 {
              background-color: transparent;
              outline: none;
              border: none;
              padding: 10px 20px;
              border: 1px solid #AB0F0C;
              color: #AB0F0C;
              background-color: rgba(#AB0F0C, 0.1);
              font-weight: 600;
              border-radius: 8px;
              cursor: pointer;
            }

            .button2 {
              background-color: transparent;
              outline: none;
              border: none;
              padding: 10px 20px;
              background-color: #AB0F0C;
              color: white;
              font-weight: 600;
              border-radius: 8px;
              cursor: pointer;
            }

          }

        }

        @media screen and (max-width: 1100px){
          .distance-section{
            flex-direction: column;
            align-items: flex-start;

            .sliding-section{
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .properties-container {

    .container{
      width: 90%;
    }
  }
}
@media screen and (max-width: 850px) {
  .properties-container {

    .container{
      .box{
        width: 100%;
        flex-direction: column;

        .box-left{
          width: 100%;
          height: fit-content;

          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .properties-container {

    padding: 15px 20px;
  }
}