.properties-container {
  width: 100%;
  height: 100%;
  padding: 20px 20px 50px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  // overflow: hidden;
}
@media screen and (max-width: 480px){
  .properties-container{

    padding: 15px 20px;
  }
}
