.CompactTractors{
    width: 100%;
    overflow: hidden;

    .Banner {
        width: 100%;
        min-height: 88vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2pc;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        h1 {
            color: white;
            font-size: 4.5rem;
            font-weight: 600;
            margin: 0px auto;
            width: 80%;
        }

        .paragraphOne {
            font-size: 24px;
            color: white;
            margin: 0px auto;
            width: 80%;
        }

        @media screen and (max-width: 767px) {

            .paragraphOne {
                font-size: 20px;
            }
        }
    }

    .FirstComponent {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        background-color: #f2f3f8;

        .container {
            width: 80%;
            margin: auto;
            display: flex;
            align-items: center;
            gap: 20px;

            .left-container {
                width: 50%;

                .mainHeading {
                    font-size: 56px;
                    font-weight: 700;
                }

                .paragraphsContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;
                    margin: auto;
                    margin-top: 20px;

                    .box {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .box-top {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-weight: 700;
                            font-size: 24px;
                        }

                        .box-middle {
                            width: calc(100% - 40px);
                            height: 2px;
                            background-color: gray;
                            margin-left: 30px;
                            border-radius: 999px;
                            position: relative;
                            overflow: hidden;

                            .inside-box-middle {
                                width: 100%;
                                height: 100%;
                                background-color: #AB0F0C;
                                border-radius: 999px;
                                position: absolute;
                                left: -100%;
                                top: 0px;
                                animation: insideBoxMove 3s linear 0s infinite;
                            }
                        }

                        .box-bottom {
                            padding-left: 40px;
                        }
                    }
                }
            }

            .right-container {
                width: 50%;
                height: fit-content;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 90%;
                    margin: auto;
                    height: 90vh;
                    object-fit: cover;
                }
            }
        }
    }

    .SecondComponent {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #f2f3f8;

        .mainHeading {
            width: 80%;
            margin: auto;
            text-align: center;
            font-weight: 700;
            font-size: 56px;
        }

        .container {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            gap: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .box {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .boxTop {
                    display: flex;
                    gap: 10px;

                    .boxTopRight {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-size: 26px;
                        font-weight: 600;
                    }

                    @media screen and (max-width: 479px) {
                        .boxTopRight {
                            font-size: 20px;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        .boxTopRight {
                            font-size: 24px;
                        }
                    }
                }

                .boxBottom {
                    padding: 10px;
                    font-size: 22px;
                }

                @media screen and (max-width: 500px) {
                    .boxTop {
                        flex-direction: column;
                        text-align: center;

                        video {
                            margin: auto;
                        }
                    }

                    .box-bottom {
                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .ThirdComponent {
        width: 100%;
        height: 500px;
        background-color: gray;
        background-image: url('https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/contact_background1.webp');
        background-blend-mode: multiply;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 30px;

        .container{
            width: 80%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            color: white;
            margin: 0px auto;
            text-align: center;

            .mainHeading{
                font-size: 32px;
                font-weight: 600;
            }

            .subHeading{
                font-size: 24px;
                font-weight: 600;
            }

            button{
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #AB0F0C;
                color: white;
                outline: none;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }

    .SeventhComponent {
        width: 100%;
        background-color: black;
        padding: 40px 10%;

        .seventh_container {
            width: 100%;
            background-color: rgba(white, 0.1);
            padding: 48px 20px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            h1 {
                color: white;
                text-align: center;
            }

            .data_container {
                width: 100%;
                display: grid;
                gap: 32px;
                grid-template-columns: repeat(2, 1fr);

                .data_box {
                    width: 100%;
                    max-width: 560px;
                    margin: 0px auto;
                    display: flex;
                    gap: 16px;

                    .data_text_container{
                        font-size: 22px;
                        color: white;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .CompactTractors{
    
        .Banner {

            h1 {
                width: 90%;
            }

            .paragraphOne {
                width: 90%;
            }
        }

        .FirstComponent{
            .container {
                width: 90%;
                flex-direction: column;

                .left-container {
                    width: 90%;
                }

                .right-container {
                    width: 100%;
                }
            }
        }

        .SecondComponent {
            .mainHeading {
                width: 90%;
            }

            .container {
                width: 90%;
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .ThirdComponent{
            min-height: 500px;
    
            .container{
                width: 90%;
                margin: 0px auto;
                text-align: center;
    
                .subHeading{
                    font-size: 16px;
                }
            }
        }

        .SeventhComponent{
            padding: 40px 5%;
        }
    }
}

@media screen and (max-width: 900px) {
    .CompactTractors{
    
        .Banner {
            h1{
                font-size: 32px;
            }
        }

        .SeventhComponent{
            .seventh_container{
            .data_container{
                grid-template-columns: repeat(1, 1fr);
            }
        }
        }
    }
}

@media screen and (max-width: 767px) {
    .CompactTractors{
    
        .Banner {
            h1{
                font-size: 26px;
            }
        }

        .FirstComponent {
            .container {
                .left-container {
                    .mainHeading {
                        font-size: 40px;
                    }
                }
            }
        }

        .SecondComponent {
            .mainHeading {
                font-size: 40px;
            }
        }
    }
}