.heroSection {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65f14c871e7c533aefc4db43_hero-bg.webp');
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
}


.blueGradient {
  position: absolute;
  inset: 0;
}

.blueGradient::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(37, 99, 235, 0.3);
  filter: blur(150px);
  transform: translateX(33%);
}

.content {
  position: relative;
  z-index: 10;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 1rem 4rem;
}

.headerTags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  color: white;
}

.tags {
  display: flex;
  gap: 0.5rem;
}

.tag {
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  background-color:transparent;
  border: 1px solid white;
  backdrop-filter: blur(4px);
  font-size: 0.875rem;
}

.mainHeading {
  text-align: center;
  max-width: 64rem;
  margin: 0 auto 2rem;
  font-size: 2.25rem;
  font-weight: bold;
  color: white;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .mainHeading {
    font-size: 3.75rem;
  }
}

@media (min-width: 1024px) {
  .mainHeading {
    font-size: 4.5rem;
  }
}

.highlight {
  color: #CCFF00;
}

.underline {
  position: relative;
  display: inline-block;
}

.underlineImage {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
}
.buttonGroup {
  display: flex;
  justify-content: center;
  margin: 0 auto 3rem;
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CCFF00;
  padding: 0.75rem;
  border-radius: 12px 12px 9px 12px;
  transition: background-color 0.3s;
}

.ctaButton {
  background-color: #CCFF00;
  color: black;
  padding: 0.75rem 1.5rem;
  border-radius: 12px 12px 10px 12px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.ctaButton:hover {
  background-color: rgba(204, 255, 0, 0.9);
}

.arrow {
  transition: transform 0.3s;
}

.ctaButton:hover .arrow {
  transform: translate(2px, 2px);
}

.descriptionContainer {
  position: relative;
  max-width: 48rem;
  margin: 0 auto 4rem;
}

.star {
  position: absolute;
  top: -24px;
  left: -24px;
  animation: sparkle 2s infinite;
}

@keyframes sparkle {
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.2) rotate(180deg); }
  100% { transform: scale(1) rotate(360deg); }
}

.description {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 1.125rem;
}

.clientLogos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.logoWrapper {
  flex: 1;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  opacity: 0.5;
  transition: opacity 0.3s;
  height: 40px; /* You can also define the height here */
  width: 120px; /* You can also define the width here */
}

.logo:hover {
  opacity: 0.75;
}
@media (max-width: 1024px) {
  .star {
    position: absolute;
    top: -24px;
    left: -10px;
    animation: sparkle 2s infinite;
  }
  .headerTags {
   flex-wrap: wrap;
  }
  .heroSection {
    min-height: 80vh; /* Decrease min-height on medium screens */
  }
}

@media (max-width: 768px) {
  .heroSection {
    min-height: 60vh; /* Further decrease min-height on smaller screens */
  }
}

@media (max-width: 480px) {
  .heroSection {
    min-height: 50vh; /* Even smaller min-height on extra small screens */
  }
}