.PricingComponent {
    width: 100%;
    padding: 50px 0px;
    background-color: #f2f3f8;
    overflow: auto;

    .price-period{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-weight: 600;

        .switch{
            width: 60px;
            height: 30px;
            border: 1px solid #AB0F0C;
            background-color: #AB0F0C;
            border-radius: 9999px;
            position: relative;

            .switch-active{
                width: 26px;
                height: 26px;
                background-color: white;
                position: absolute;
                top: 1px;
                // left: -1px;
                // right: -1px;
                border-radius: 9999px;
                cursor: pointer;
            }
        }
    }

    // .price-format{
    //     width: 1000px;
    //     margin-top: 20px;
    //     margin-left: auto;
    //     margin-right: auto;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 10px;
    //     // overflow: auto;

    //     .headings{
    //         display: flex;
    //         align-items: center;
    //         gap: 10px;

    //         .offerings{
    //             flex: 4;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //         }

    //         .plan{
    //             flex: 2;
    //             display: flex;
    //             flex-direction: column;
    //             align-items: center;
    //             justify-content: center;
    //             gap: 16px;
    //             padding: 10px 0px;
    //             border: 2px solid #AB0F0C;
    //             border-radius: 10px;

    //             .accessibility{
    //                 font-size: 14px;
    //                 font-weight: 500;
    //                 display: flex;
    //                 flex-direction: column;
    //                 align-items: center;
    //                 justify-content: center;
    //                 gap: 6px;
    //             }
    //         }
    //     }

    //     .offers{
    //         display: flex;
    //         align-items: center;
    //         gap: 10px;

    //         .offer-heading{
    //             flex: 4;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //         }

    //         .offer-allow{
    //             flex: 2;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //         }
    //     }
    // }

    .price-format{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 40px;

        .price-box{
            width: 300px;
            padding: 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .plan-name{
                font-weight: bold;
                font-size: 18px;
            }

            .plan-price{
                font-weight: bold;
                font-size: 20px;
            }

            .benifits-array{
                display: flex;
                flex-direction: column;
                width: 100%;

                .benefit{
                    text-align: center;
                    padding: 10px 0px;
                    border-top: 1px solid;
                    border-bottom: 1px solid;
                }
            }

            button{
                border: none;
                outline: none;
                padding: 10px 0px;
                text-align: center;
                width: 100%;
                font-weight: bold;
                font-size: 18px;
                border-radius: 6px;
            }
        }
    }
    
}

@media screen and (max-width: 1000px){
    .PricingComponent{

        .price-format{
flex-direction: column;
        }
    }
}