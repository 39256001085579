.LanguageOptions {
    background-color: white;
    border-radius: 6px;
    padding: 6px 10px;
    padding-right: 40px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    margin-top: 10px;

    .languageList{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .listBox{
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}