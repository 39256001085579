.ServiciosDeSeguimineto {
    width: 100%;
    min-height: 100vh;
    max-height: fit-content;
    overflow: hidden;

    .BannerComponent {
        width: 100%;
        min-height: 80vh;
        height: fit-content;
        display: flex;

        .leftContainer {
            width: 50%;
            height: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .shadow {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: linear-gradient(to right, rgba(50, 50, 50, 1), rgba(50, 50, 50, 0));
                display: none;
            }

            .textContainer {
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 12px;

                p {
                    font-weight: 600;
                }

                .mainHeading {
                    font-size: 53px;
                }

                .paragraphOne {
                    font-size: 24px;
                }

                .emailGetStartedComponent {
                    border: 1px solid #f2f3f8;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 9999px;
                    overflow: hidden;
                    width: fit-content;

                    input {
                        width: 200px;
                        background: transparent;
                        outline: none;
                        border: none;
                    }

                    button {
                        padding: 10px;
                        background: transparent;
                        outline: none;
                        border: none;
                        font-weight: 600;
                        font-size: 20px;
                        border-left: 1px solid #f2f3f8;
                        color: #f2f3f8;
                        cursor: pointer;
                    }
                }

                @media screen and (max-width: 767px) {

                    .mainHeading {
                        font-size: 40px;
                    }

                    .paragraphOne {
                        font-size: 20px;
                    }
                }
            }
        }

        .rightContainer {
            width: 50%;
            height: 100%;
        }
    }

    .FirstComponent {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;

        .headings {
            width: 80%;
            margin: auto;
            margin-bottom: 20px;

            h2 {
                font-weight: 600;
                font-size: 52px;
            }

            p {
                font-size: 18px;
                margin-top: 16px;
            }

            @media screen and (max-width: 1680px) {
                h2 {
                    font-size: 52px;
                }

                p {
                    font-size: 18px;
                }
            }

            @media screen and (max-width: 1440px) {
                h2 {
                    font-size: 50px;
                }

                p {
                    font-size: 17px;
                }
            }

            @media screen and (max-width: 1280px) {
                h2 {
                    font-size: 46px;
                }

                p {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1200px) {
                h2 {
                    font-size: 40px;
                }

                p {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1024px) {
                h2 {
                    font-size: 36px;
                }

                p {
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 912px) {
                h2 {
                    font-size: 32px;
                }
            }

            @media screen and (max-width: 650px) {
                h2 {
                    font-size: 22px;
                }
            }
        }

        .Container {
            height: fit-content;
            width: 80%;
            margin: auto;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #AB0F0C;
            display: flex;
            box-sizing: border-box;

            .right-container {
                padding: 50px;
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .contents {
                    width: 100%;
                    height: fit-content;
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    gap: 26px;

                    img {
                        width: 60px;
                    }

                    .Heading {
                        font-weight: 600;
                        font-size: 32px;
                    }

                    .Paragraph {
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
            }

            .left-container {
                box-sizing: border-box;
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 100px;
                padding-bottom: 80px;
                width: 50%;
                height: 100%;
                background: linear-gradient(90deg, rgba(171, 15, 12, 1) 43%, rgba(184, 38, 9, 0.9808298319327731) 81%, rgba(200, 98, 70, 0.9472163865546218) 100%);

                .list-container {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: rgba(255, 255, 255, 0.497);
                        border-radius: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: white;
                        /* Color of the thumb */
                        border-radius: 5px;
                        /* Rounded corners of the thumb */
                    }

                    .box {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        cursor: pointer;

                        .heading {
                            color: white;
                            font-size: 20px;
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }

                        .box-bottom {
                            width: calc(100% - 60px);
                            margin-left: 30px;
                            height: 1px;
                        }
                    }
                }
            }
        }
    }

    .ServiceFooter{
        width: 100%;
        padding: 40px 10%;
        height: fit-content;
        background-color: #f2f3f8;
        display: flex;
        align-items: center;
        gap: 20px;

        .left{
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                // height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .right{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;

            .headingOne{
                color: black;
                font-weight: 500;
                font-size: 40px;
                width: 80%;
            }

            .subHeading{
                color: black;
                font-size: 20px;
                width: 80%;
                text-align: start;
            }

            .button{
                padding: 10px 20px;
                background: #AB0F0C;
                color: white;
                cursor: pointer;
                border-radius: 10px;
            }
        }
    }

    .FifthComponent {
        width: 100%;
        padding: 40px 10%;
        background-color: #AB0F0C;

        h2 {
            font-size: 36px;
            color: white;
            font-weight: bold;
            text-align: center;
        }

        .Fifth_container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;
            margin-top: 30px;

            .container_left {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 24px;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 12px;
                }
            }

            .container_right {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .right_container {
                    width: 100%;
                    color: white;

                    .map_container {
                        width: 100%;
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        position: relative;
                        padding-bottom: 40px;

                        &::before {
                            position: absolute;
                            left: 28px;
                            height: 100%;
                            width: 4px;
                            background-color: rgba(white, 20);
                        }

                        h4 {
                            font-weight: bold;
                            font-size: 24px;
                            transition: all 500ms;
                        }
                    }

                    .map_container_alt {
                        width: 100%;
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        position: relative;
                        padding-bottom: 40px;

                        h4 {
                            font-weight: bold;
                            font-size: 24px;
                            transition: all 500ms;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .ServiciosDeSeguimineto {
        .BannerComponent {
            position: relative;
            color: white;

            .leftContainer {
                width: 100%;
                background-color: transparent;

                .shadow {
                    display: block;
                }

                .textContainer {
                    width: 90%;
                }
            }

            .rightContainer {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                z-index: -2;
            }
        }

        .FirstComponent {
            .headings {
                width: 90%;
            }

            .Container {
                width: 90%;
                flex-direction: column-reverse;
                height: fit-content;

                .left-container {
                    width: 100%;
                }

                .right-container {
                    width: 100%;
                }
            }
        }

        .ServiceFooter{
            padding: 40px 5%;

            .left{
                display: none;
            }

            .right{
                flex: 1;
            }
        }

        .FifthComponent{
            padding: 40px 5%;

            .Fifth_container{
                gap: 20px;
                flex-direction: column;

                .container_left {
                    width: 100%;
                    gap: 20px;
                }

                .container_right {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .ServiciosDeSeguimineto {
        .ServiceFooter{
    
            .right{
                flex: 1;

                .headingOne{
                    font-size: 26px;
                }
            }
        }
    }
}