.sliderContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #f7fafc;
  }
  
  .sliderTrack {
    display: flex;
    animation: scroll 10s linear infinite;
    width: calc(200%); /* Extend width for continuous scroll */
  }
  
  .slide {
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
   
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
    text-align: center;
    min-width: 100px;
  }
  
  .stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 120px;
  }
  
  .number {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
  
  .text {
    font-size: 14px;
    color: #666;
    text-align: center;
  }
  
  .divider {
    width: 1px;
    height: 70px;
    background-color: #e0e0e0;
    margin: 0 20px;
  }
  
  /* Keyframes for infinite scrolling */
  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  