@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(171, 15, 12, 0.4);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(171, 15, 12, 0.2);
    }
  }

.forbuilder-container{
    width: 750px;
    height: 55vh;
    position: relative;
    margin: 0px auto;
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    box-shadow: 0px 4px 4px 1px #555;
    z-index: 50;
    .builder-box-one{
        width: 30%;
        height: 100%;
        position: relative;
        background-color: aliceblue;
        padding: 30px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        z-index: 50;
        p{
            font-size: 12px;
            margin-top: 20px;
            font-weight: 600;
            cursor: pointer;
           
        }
        .box-one-bottom{
            position: absolute;
            bottom: 5px;
            width: 100%;
            p{
                color: rgb(118, 118, 118);
                font-size: 12px;
                font-weight: 600;
            }
            span{
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
    .builder-box-two{
        width: 35%;
        height: 100%;
        padding: 30px;
        font-weight: 600;
        p{  width: 100%;
            font-size: 12px;
            padding: 3px 5px;
            color: rgb(120, 120, 120);
            cursor: pointer;
            &:hover{
                background-color: rgb(244, 248, 255);
            }
        }
        .box-two-text{
           
            p{
                font-size: 12px;
                margin-top: 20px;
                color: black;

                button{
                    width: 110px;
                    background-color: transparent;
                    padding: 10px 0px;
                    border: 1px solid #AB0F0C;
                    color: #AB0F0C;
                    border-radius: 3px;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }
    }
    .builder-box-three{
        width: 35%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img{
            width: 250px;
            cursor: pointer;
        }

        .awarded-section{
            width: 500px;
            margin-right: 20px;
            margin-bottom: 20px;
            margin-left: -260px;

            p {
                text-align: right;
            }

            .award-images{
                display: flex;
                align-items: center;
                gap: 12px;
                flex-wrap: wrap;
                justify-content: end;
                margin-top: 10px;

                img {
                    height: 40px;
                    width: fit-content;
                    object-fit: cover;
                }
            }
        }
    }

    .builder-box-four{
        position: absolute;
        bottom: 40px;
        right: 50px;
        display: flex;
        align-items: center;
        gap: 20px;

        a{
            text-decoration: none;
            color: white;
            font-size: 16px;

            .button{
                // background: #AB0F0C;
                border: 1px solid #AB0F0C;
                color: #AB0F0C;
                padding: 8px 16px;
                border-radius: 5px;
                transition: all 0.5s;

                &:hover{
                    animation: pulse-animation 1s;
                    color: black;
                }
            }
        }
    }
}

// --------------------- Responsive Design ----------------

@media screen and (max-width: 480px){
    .forbuilder-container{
        width: 300px;
        height: 100%;
        flex-direction: column;
        .builder-box-one{
            width: 100%;
            height: 100%;
            padding: 20px 30px;
           
            .box-one-bottom{
                display: none;
            }
        }
        .builder-box-two{
            width: 100%;
        }
        .builder-box-three{
            width: 100%;
            text-align: center;
        }
    }
}