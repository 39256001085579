.housing-edge-section {
  width: 100%;
  // height: 100vh;
  background-color: #f2f3f8;
  padding: 40px 10%;
  font-family: "Poppins", sans-serif;
  .housing-heading {
    display: flex;
    align-items: center;
    gap: 13px;
    p {
      font-size: 28px;
      font-weight: 500;
      span {
        font-weight: 600;
      }
    }
    .edge-btn {
      button {
        padding: 2px 15px;
        color: #AB0F0C;
        font-size: 12px;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        border: 1px solid #AB0F0C;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .housing-wrapper {
    display: flex;
    position: relative;
    .housing-edge-data {
      width: auto;
      height: auto;
      margin-top: 20px;
      transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
      display: flex;
      //   align-items: center;
      gap: 10px;
      overflow-x: scroll;
      scroll-behavior: smooth;
      position: relative;

      .housing-container {
        min-width: 160px;
        max-height: 160px;
        min-height: 140px;
        max-height: 180px;
        padding: 10px;
        margin: 8px 5px;
        border-radius: 10px;
        box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.07);
        transition: all 0.2s;
        opacity: 1;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 2px 3px 10px rgba(0, 0, 0, 0.07);
        }
        .image-section {
          width: 100%;
          img{
            width: 70px;
            height: 70px;
            object-fit: cover;
          }
        }
        .title-section {
          width: 100%;
          h4 {
            font-size: 13px;
          }
        }
      }
    }
    .next {
      padding: 9px 12px;
      background-color: white;
      border-radius: 999px;
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(-0%, -50%);
      border: none;
      filter: drop-shadow(0px 0px 5px #555);
    }
    .prev{
        padding: 9px 12px;
      background-color: white;
        border-radius: 999px;
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(-0%, -50%);
        z-index: 5;
        border: none;
        filter: drop-shadow(0px 0px 5px #555);
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
}

// ----------- Responsive Design -------------

@media screen and (max-width: 1000px) {
  .housing-edge-section{
    padding: 40px 5%;
  }
}
