.navbar-container {
  width: 100%;
  height: 12vh;
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  // background-color: #AB0F0C;
  z-index: 50;
  // box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.75);

  .navbar-section {
    width: 100%;
    height: 100%;
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-left {
      display: flex;
      align-items: center;
      gap: 15px;
      p{
        // visibility: hidden;
        // opacity: 0;
        display: none;
        color: white;
        font-size: 24px;
      }
      .logo {
        img {
          width: 150px;
          text-align: left;
          cursor: pointer;
        }
      }
    }
    .nav-right {
      display: flex;
      align-items: center;
      gap: 35px;
      margin-right: 10px;
      .ul {
        display: flex;
        gap: 30px;
        .nav-list{
          position: relative;
          transition: linear 0.5s;
          li {
            list-style: none;
            a {
              text-decoration: none;
              color: white;
              opacity: 0.8;
              font-size: 14px;
              font-weight: 600;
               &:hover{
              border-bottom: 2px solid white;
            }
              
            }
           
          }
          .nav-owner{
            position: absolute;
            z-index: 6;
            top: 100%;
            margin-top: 15px;
            right: -100%;
            transition: linear 0.3s;
          }
          .nav-tenant{
            position: absolute;
            top: 100%;
            z-index: 6;
            margin-top: 15px;
            right: -400%;
            transition: linear 0.3s;
          }
          .nav-builder{
            position: absolute;
            top: 100%;
            z-index: 6;
            margin-top: 15px;
            right: 0% ;
            transition: linear 0.3s;
          }
          .nav-insight{
            position: absolute;
            top: 100%;
            z-index: 6;
            margin-top: 15px;
            right: 0% ;
            transition: linear 0.3s;
          }
          .nav-hide{
            opacity: 0;
            visibility: hidden;
          }
        }
       
      }
      .nav-btn {
        button {
          padding: 5px 15px;
          border: none;
          background-color: white;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          font-family: 'Open Sans', sans-serif;
          position: relative;
        }
      }
      .user-hover {
        position: relative;
        z-index: 4;
        .user-account {
          padding: 5px 10px;
          display: flex;
          gap: 2px;
          cursor: pointer;

          &:hover{
            span .fa-chevron-down{
              transform: rotate(180deg);
            }
          }

          p {
            width: 25px;
            background-color: white;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #AB0F0C;
            .fa-solid {
              padding: 4px 2px;
              font-size: 15px;
            }
          }
          span .fa-solid{
            color: white;
          }
          span .fa-chevron-down{
            transition: all 500ms;
          }
          
        }
        .user-show{
          position: absolute;
          top: 110%;
          right: 0%;
          transition: linear 0.3s;
        }
        .hide{
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .resNavHide {
      transform: translateX(-100%);
      transition: all 500ms;
    }
    .resNavShow {
      transform: translateX(0px);
      transition: all 500ms;
    }

    .resNav {
      z-index: 10;
      background-color: #AB0F0C;
      filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
      padding: 16px;
      border-radius: 12px;
      display: flex;
      gap: 8px;
      color: white;
    }
  }

}
.nav-bg{
  // background: linear-gradient(360deg, rgba(0,0,0,0.6), rgba(0,0,0,0.75));
  // background-color: #AB0F0C;
}

// ---------------- Responsive Style -----------------

@media screen and (max-width: 1200px){
  .navbar-container{
    height: 12vh;
    .navbar-section{
      padding: 0px 10px;
      .nav-left{
        p{
          display: block;
          z-index: 4;
        }
        .logo{
          img{
            width: 100px;
          }
        }
      }
      .nav-right{
        margin: 0px;
        ul {
          .nav-list{
            display: none;
          }
        }
      }
    }
  }
}

