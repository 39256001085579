@keyframes moving {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.Partner {
    width: 100%;

    .Banner {
        height: 88vh;
        width: 100%;
        position: relative;
        padding: 0px 10%;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;

        video {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        h1 {
            width: 50%;
            font-size: 42px;
            font-weight: 600;
        }

        p {
            width: 50%;
            font-size: 20px;
        }

        .buttons_div {
            display: flex;
            align-items: center;
            gap: 20px;

            .get_started_button {
                width: fit-content;
                padding: 8px 20px;
                border-radius: 8px;
                background-color: #AB0F0C;
                color: white;
                outline: none;
                border: none;
                font-size: 18px;
                font-weight: 500;
            }

            .talk_to_an_expert_button {
                width: fit-content;
                padding: 8px 20px;
                border-radius: 8px;
                color: #AB0F0C;
                background-color: white;
                outline: none;
                border: none;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }

    .FirstComponent {
        width: 80%;
        margin: 40px auto;

        .topPart {
            width: 100%;
            display: flex;
            gap: 30px;

            .topLeft {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .topRight {
                width: 50%;
                display: flex;
                align-items: end;
                justify-content: end;
                gap: 16px;

                .Button {
                    width: 40px;
                    height: 40px;
                    background-color: white;
                    border-radius: 999px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px, rgba(51, 51, 51, 0.05) 0px 0px 4px;

                    &:nth-child(1) {
                        width: 40px;
                    }
                }
            }
        }

        .bottomPart {
            margin-top: 40px;
            display: flex;
            overflow-x: scroll;
            scroll-behavior: smooth;
            transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
            gap: 30px;

            .boxDetails {
                min-width: 31%;
                max-width: 31%;

                .tag {
                    background-color: #AB0F0C;
                    color: white;
                    font-size: 20px;
                    padding: 6px 10px;
                    border-radius: 99px;
                    width: fit-content;
                }

                .box {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid rgba(gray, 0.4);
                    border-radius: 12px;
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 12px;
                    }

                    p {
                        color: #747474;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .SecondComponent {
        width: 80%;
        margin: 40px auto;
        overflow: hidden;

        .customerImage {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
            white-space: nowrap;
            flex-shrink: 0;
            animation: moving 16s linear infinite;
            margin-top: 20px;

            img {
                height: 80px;
                width: auto;
                object-fit: cover;
            }
        }
    }

    .ThirdComponent {
        height: 80vh;
        width: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            position: absolute;
            top: 0px;
            left: 0px;
        }

        .container {
            width: 80%;
            margin: auto;
            height: 100%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            p {
                font-size: 20px;
                font-weight: 500;
            }

            .boxContainer {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;

                .box {
                    height: 160px;
                    width: 300px;
                    padding: 0px 40px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    justify-content: center;
                    background-color: rgba(black, 0.4);
                    border: 2px solid white;
                    border-radius: 10px;
                }
            }
        }
    }

    .FourthComponent {
        width: 80%;
        margin: 40px auto;

        .container {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .buttonContainer {
                display: flex;
                align-items: center;
                gap: 20px;

                button {
                    outline: none;
                    border: none;
                    color: white;
                    font-weight: 500;
                    font-size: 18px;
                    padding: 8px 20px;
                    border-radius: 8px;
                }

                .seePricing {
                    background-color: #AB0F0C;
                }

                .talkToAnExpert {
                    background-color: black;
                }
            }
        }

    }

    .FifthComponent {
        width: 100%;
        padding: 40px 10%;
        background-color: black;

        .Fifth_container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;

            .container_left {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 24px;

                h2 {
                    font-size: 36px;
                    color: white;
                    font-weight: bold;
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 12px;
                }
            }

            .container_right {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .right_container {
                    width: 100%;
                    color: white;

                    .map_container {
                        width: 100%;
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        position: relative;
                        padding-bottom: 40px;

                        &::before {
                            position: absolute;
                            left: 28px;
                            height: 100%;
                            width: 4px;
                            background-color: rgba(white, 20);
                        }

                        h4 {
                            font-weight: bold;
                            font-size: 24px;
                            transition: all 500ms;
                        }
                    }

                    .map_container_alt {
                        width: 100%;
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        position: relative;
                        padding-bottom: 40px;

                        h4 {
                            font-weight: bold;
                            font-size: 24px;
                            transition: all 500ms;
                        }
                    }
                }
            }
        }
    }

    .SixthComponent {
        width: 100%;
        padding: 40px 10%;
        background-color: black;

        h1 {
            font-size: 32px;
            color: white;
            text-align: center;
            font-weight: bold;
        }

        .sixth_container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 40px;
            gap: 48px;

            .box {
                width: 100%;
                background-color: rgba(white, 0.1);
                padding: 20px 20px;
                border-radius: 12px;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 32px;
            }
        }
    }

    .SeventhComponent {
        width: 100%;
        background-color: black;
        padding: 40px 10%;

        .seventh_container {
            width: 100%;
            background-color: rgba(white, 0.1);
            padding: 48px 20px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            h1 {
                color: white;
                text-align: center;
            }

            .data_container {
                width: 100%;
                display: grid;
                gap: 32px;
                grid-template-columns: repeat(2, 1fr);

                .data_box {
                    width: 100%;
                    max-width: 560px;
                    margin: 0px auto;
                    display: flex;
                    gap: 16px;

                    .data_text_container{
                        font-size: 22px;
                        color: white;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px){
    .Partner{

        .SixthComponent{

            .sixth_container {
                grid-template-columns: repeat(3, 1fr);
                gap: 48px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .Partner{

        .SixthComponent{

            .sixth_container {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .Partner{
        .Banner {
            padding: 0px 5%;

            h1{
                width: 75%;
            }

            p{
                width: 75%;
            }
        }

        .FirstComponent{
            width: 90%;

            .topPart{
                .topLeft{
                    width: 75%;
                }

                .topRight{
                    width: 25%;
                }
            }

            .bottomPart{
                .boxDetails{
                    min-width: 44%;
                    max-width: 44%;
                }
            }
        }

        .SecondComponent{
            width: 90%;
        }

        .ThirdComponent{
            height: fit-content;
            padding: 40px 0px;
            .container{
                width: 90%;
                gap: 16px;

                .boxContainer{
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        .FourthComponent{
            width: 90%;

            .container{
                width: 75%;
            }
        }

        .FifthComponent{
            padding: 40px 5%;

            .Fifth_container{
                gap: 20px;
                flex-direction: column;

                .container_left {
                    width: 100%;
                    gap: 20px;

                    h2 {
                        text-align: center;
                    }
                }

                .container_right {
                    width: 100%;
                }
            }
        }

        .SixthComponent{
            padding: 40px 5%;
        }

        .SeventhComponent{
            padding: 40px 5%;
        }
    }
}

@media screen and (max-width: 800px){
    .Partner{

        .SeventhComponent{
            .seventh_container{
            .data_container{
                grid-template-columns: repeat(1, 1fr);
            }
        }
        }
    }
}

@media screen and (max-width: 768px){
    .Partner{
        .Banner {

            h1{
                width: 100%;
            }

            p{
                width: 100%;
            }
        }

        .FirstComponent{

            .topPart{
                flex-direction: column;
                .topLeft{
                    width: 100%;
                }

                .topRight{
                    width: 100%;
                }
            }

            .bottomPart{
                .boxDetails{
                    min-width: 86%;
                    max-width: 86%;
                }
            }
        }

        .ThirdComponent{
            .container{

                .boxContainer{
                    .box{
                        width: 100%;
                        min-height: 160px;
                        height: fit-content;
                    }
                }
            }
        }

        .FourthComponent{

            .container{
                width: 100%;
            }
        }

        .SixthComponent{

            .sixth_container {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
            }
        }
    }
}