.error-page{
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // background-color: red;
    .error{
        
        p{
            padding: 20px 10px;
            font-size: 22px;
            font-weight: 500;
        }
        button{
            width: 120px;
            border: none;
            outline: none;
            padding: 6px 10px;
            cursor: pointer;
            border-radius: 3px;
            color: white;
            font-weight: 500;
            background-color: #0078DB;
        }
    }
}