.ServiciosDeSoporteDeAgentes {
    width: 100%;

    .banner {
        height: 80vh;
        width: 100%;
        position: relative;

        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -2;
        }

        .shadow {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: linear-gradient(to right, rgba(50, 50, 50, 1), rgba(50, 50, 50, 0));
        }

        .bannerTextContainer {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .bannerText {
                width: 80%;
                margin: auto;
                color: white;
                display: flex;
                flex-direction: column;
                gap: 10px;

                :nth-child(1) {
                    font-size: 24px;
                    font-weight: 500;
                    color: #b8babc;
                }

                :nth-child(2) {
                    font-size: 56px;
                    font-weight: 700;
                }

                button {
                    padding: 10px 20px;
                    border: 1px solid #AB0F0C;
                    color: #AB0F0C;
                    border-radius: 9999px;
                    background-color: transparent;
                    outline: none;
                    width: fit-content;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
    }

    .SecondComponent{
        width: 100%;
        padding: 40px 0px;
        background-color: #f2f3f8;
        background-color: black;

        .container{
            width: 80%;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 30px;
            color: white;

            .mainHeading{
                font-weight: 600;
                font-size: 56px;
                width: 80%;
            }

            .subHeading{
                font-size: 22px;
                width: 80%;
            }

            .elementsContainer{
                width: 100%;
                display: flex;
                gap: 20px;

                .left{
                    width: 50%;

                    img{
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }

                .right{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 20px;

                    .box{
                        display: flex;
                        gap: 20px;

                        .box-left{
                            min-width: 40px;
                            max-width: 40px;
                            min-height: 40px;
                            max-height: 40px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #AB0F0C;
                        }

                        .box-right{
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            .heading{
                                font-size: 22px;
                                font-weight: 700;
                            }

                            .details{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 1680px){
                .mainHeading{
                    font-size: 52px;
                }
            }

            @media screen and (max-width: 1440px){
                .mainHeading{
                    font-size: 50px;
                }
            }

            @media screen and (max-width: 1280px){
                .mainHeading{
                    font-size: 46px;
                }
            }
            @media screen and (max-width: 1200px){
                .mainHeading{
                    font-size: 40px;
                }
            }
            @media screen and (max-width: 1024px){
                .mainHeading{
                    font-size: 36px;
                }

                .subHeading{
                    font-size: 16px;
                }
            }
            @media screen and (max-width: 912px){
                .mainHeading{
                    font-size: 32px;
                }
            }
            @media screen and (max-width: 820px){
                .mainHeading{
                    font-size: 30px;
                }
            }
            @media screen and (max-width: 767px){
                .mainHeading{
                    font-size: 27px;
                }
            }
            @media screen and (max-width: 650px){
                .mainHeading{
                    font-size: 22px;
                    font-weight: 500;
                }
                .elementsContainer{
    
                    .right{
    
                        .box{
    
                            .box-right{
    
                                .heading{
                                    font-size: 18px;
                                    font-weight: 500;
                                }
    
                                .details{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ThirdComponent{
        width: 80%;
        padding: 40px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 0px auto;

        .left{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 100%;
                border-radius: 10px;
            }
        }

        .rigth{
            width: 50%;

            .heading{
                font-weight: 500;
                font-size: 32px;
                margin-bottom: 20px;
            }

            .box-top{
                font-weight: 500px;
                margin-bottom: 20px;
                font-size: 26px;
                width: 100%;
                padding: 8px 10px;
                background-color: #f2f3f8;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                border-radius: 10px 10px 0px 0px;
            }

            .box-bottom{
                transition: all 500ms;
                cursor: pointer;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 8px 10px;
                width: 100%;
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }

    .FourthComponent{
        width: 100%;
        height: 500px;
        background-color: gray;
        background-image: url('https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/contact_background1.webp');
        background-blend-mode: multiply;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 30px;

        .container{
            width: 80%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            color: white;
            margin: 0px auto;
            text-align: center;

            .mainHeading{
                font-size: 32px;
                font-weight: 600;
            }

            .subHeading{
                font-size: 24px;
                font-weight: 600;
            }

            button{
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #AB0F0C;
                color: white;
                outline: none;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .ServiciosDeSoporteDeAgentes {
        .banner {
            .bannerTextContainer {
                .bannerText {
                    width: 90%;

                    :nth-child(2) {
                        font-size: 26px;
                    }
                }
            }
        }

        .SecondComponent{
            .container{
                width: 90%;

                .mainHeading{
                    width: 100%;
                }

                .subHeading{
                    width: 100%;
                }

                .elementsContainer{
                    flex-direction: column;

                    .left{
                        width: 100%;
                    }

                    .right{
                        width: 100%;
                    }
                }
            }
        }

        .ThirdComponent{
            width: 90%;
            flex-direction: column;

            .left{
                width: 100%;

                img{
                    width: 100%;
                }
            }

            .rigth{
                width: 100%;

                .heading{
                    font-size: 22px;
                }

                .box-top{
                    font-size: 18px;
                }

                .box-bottom{
                    font-size: 16px;
                }
            }
        }

        .FourthComponent{
            min-height: 500px;
    
            .container{
                width: 90%;
                margin: 0px auto;
                text-align: center;
    
                .mainHeading{
                    font-size: 22px;
                }
    
                .subHeading{
                    font-size: 16px;
                }
            }
        }
    }
}