
.imageDiv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
  
  .smallImage {
    width: 60%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .container {
    background-color: #0c0d39;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .card {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
      border-radius: 1.5rem;
    overflow: hidden;
    max-width: 72rem;
    width: 100%;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
  }
  
  .leftContent, .rightContent {
    padding: 2rem;
  }
  
  .logo {
    height: 2rem;
    margin-bottom: 2rem;
  }
  
  .dashboardContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dashboardImage {
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .dashboard {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
  }
  
  .stats {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .amount {
    font-size: 1.875rem;
    font-weight: bold;
    margin-right: 1rem;
  }
  
  .category {
    background-color: #f3f4f6;
    color: #1f2937;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .description {
    color: #4b5563;
    margin-bottom: 2rem;
  }
  
  .cta {
    background-color: black;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .cta:hover {
    background-color: #1f2937;
  }
  
  .arrow {
    margin-left: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
  }
  
  @media (min-width: 1024px) {
    .card {
      flex-direction: row;
    }
  
    .leftContent, .rightContent {
      width: 50%;
    }
  }
  