.Farmer {
    min-height: 100vh;
    max-height: fit-content;
    width: 100%;
    text-align: center;
    overflow: hidden;

    .Container {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;

        .FarmerPhoto {
            width: 100%;
            // margin-top: 20px;
            position: relative;

            img {
                // width: 50%;
                width: 100%;
                height: 360px;
                object-fit: cover;
            }

            .BookTractor {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 360px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    visibility: hidden;
                    opacity: 0;
                    padding: 20px;
                    background-color: rgba(0, 0, 0, 0.8);
                    border-radius: 999px;
                    color: white;
                    font-weight: bold;
                    transition: all 500ms;
                    cursor: pointer;
                }
            }

            &:hover {
                .BookTractor {
                    span {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

        .HOne {
            margin-top: 20px;
            display: flex;
            align-items: baseline;
            gap: 10px;

            :nth-child(1) {
                font-weight: bold;
                font-size: 30px;
            }

            :nth-child(2) {
                font-weight: bold;
                font-size: 20px;
            }
        }

        .POne {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 500;
        }

        .Boxes {
            width: 100%;
            margin-top: 25px;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;

            .Box {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;

                .IconContainer {
                    width: 80px;
                    height: 80px;
                    border-radius: 40px;
                    background: linear-gradient(90deg, #C4E7E3 0%, rgba(217, 217, 217, 0.00) 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    :nth-child(1){
                        width: 60%;
                        height: 60%;
                    }
                }

                .heading {
                    font-weight: bold;
                    font-size: 18px;
                }

                .sub-heading {
                    font-weight: 480;
                }
            }
        }

        .greenBannerSection {
            margin-top: 40px;
            margin-bottom: 40px;
            width: 100%;
            background: #EBF5EE;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 30px;
            padding-bottom: 30px;
            gap: 26px;

            p {
                font-weight: bold;
                font-size: 26px;
            }

            .boxes {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 50px;

                .box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    .box-left {
                        width: 30px;
                        height: 30px;
                    }

                    .box-right {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .first-line {
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .send-otp-box {
            margin-bottom: 60px;
            padding: 30px;
            border-radius: 30px;
            background: #fbece5;
            display: flex;
            gap: 80px;

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 25px;

                .firstline {
                    font-size: 22px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    :nth-child(2) {
                        font-weight: bold;
                    }
                }

                .secondline {
                    background: #F34653;
                    width: fit-content;
                    padding-right: 15px;
                    padding-left: 15px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    border-radius: 12px;
                    color: white;
                }
            }
        }

        .register-process {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 60px;

            p {
                font-weight: bold;
                font-size: 30px;
            }

            .middle-container {
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
                width: 100%;
                align-items: flex-start;
                justify-content: center;

                .Box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                    width: 30%;

                    .IconContainer {
                        width: 80px;
                        height: 80px;
                        border-radius: 40px;
                        background: linear-gradient(90deg, #C4E7E3 0%, rgba(217, 217, 217, 0.00) 100%);
                    }

                    .heading {
                        font-weight: bold;
                        font-size: 18px;
                    }

                    .sub-heading {
                        font-weight: 480;
                        max-width: 360px;
                    }
                }

            }

            .buttom-button {
                background: #F34653;
                width: fit-content;
                padding: 15px;
                border-radius: 10px;
                color: white;
                font-weight: bold;
            }
        }

        .Testimonials{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 15px;
            margin-bottom: 50px;

            .main-heading{
                font-weight: bold;
                font-size: 30px
            }

            .sub-heading{
                font-size: 20px;
            }

            .leftButton{
                width: 40px;
                height: 40px;
                background: white;
                position: absolute;
                left: 8.8%;
                top: 180px;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                border: 1px solid gray;
            }

            .rightButton{
                width: 40px;
                height: 40px;
                background: white;
                position: absolute;
                right: 8.8%;
                top: 180px;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                border: 1px solid gray;
            }

            .swiper{
                width: 80%;

                .swiper-slide{
                    .IconContainer{
                        // width: 300px;
                        height: 200px;

                        img{
                            // width: 100%;
                            aspect-ratio: 1/0.7;
                            height: 100%;
                            border-radius: 30px 0px 30px 0px;
                        }
                    }

                    .sub-heading{
                        :nth-child(1){
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .partners{
            margin-bottom: 50px;
            width: 100%;
            position: relative;
            background: #f9aeae;
            padding-bottom: 70px;
            padding-top: 20px;

            .mainHeading{
                font-weight: bold;
                font-size: 30px;
            }

            .subHeading{
                margin-top: 10px;
                margin-top: 5px;
                font-size: 20px;
            }

            .leftButton{
                width: 40px;
                height: 40px;
                background: white;
                position: absolute;
                left: 8.8%;
                bottom: 100px;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                border: 1px solid gray;
            }

            .rightButton{
                width: 40px;
                height: 40px;
                background: white;
                position: absolute;
                right: 9.8%;
                bottom: 100px;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                border: 1px solid gray;
            }

            .swiper{
                margin-top: 30px;
                width: 80%;

                .swiper-slide{
                    width: fit-content;
                    height: fit-content;
                }
            }
        }

        .earnYourTerms{
            width: 100%;
            margin-bottom: 50px;
            position: relative;

            .mainHeading{
                font-weight: bold;
                font-size: 30px;
            }

            .leftButton{
                width: 40px;
                height: 40px;
                background: white;
                position: absolute;
                left: 8.8%;
                bottom: 34%;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                border: 1px solid gray;
            }

            .rightButton{
                width: 40px;
                height: 40px;
                background: white;
                position: absolute;
                right: 8.8%;
                bottom: 34%;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                border: 1px solid gray;
            }

            .swiper{
                margin-top: 20px;
                width: 80%;

                .swiper-slide{
                    
                    .IconContainer{
                        width: 370px;
                        height: 200px;

                        iframe{
                            width: 100%;
                            height: 100%;
                            border-radius: 15px;
                        }
                    }

                    .sub-heading{
                        margin-top: 5px;
                        font-weight: bold;
                    }
                }
            }
        }

        .appDownloadPromotion{
            width: 80%;
            height: 500px;
            background: linear-gradient(90deg, #E9D8ED 0%, rgba(185, 230, 255, 0.98) 100%);
            margin-bottom: 50px;
            border-radius: 50px 50px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            overflow: hidden;

            .mainHeading{
                font-weight: bold;
                margin-left: 40px;
                margin-top: 50px;
                font-size: 30px;
            }

            .subHeading{
                margin-left: 40px;
                font-size: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            a{
                margin-left: 40px;
                margin-top: 20px;

                img{
                    width: 140px;
                }
            }

            .image{
                position: absolute;
                right: 10%;
                top: 10%;
                border-radius: 30px 30px 0px 0px;
            }
        }
    }
}

@media screen and (max-width: 1300px){
    .Farmer {
    
        .Container {
    
            .Testimonials{
    
                .leftButton{
                    left: 3.5%;
                }
    
                .rightButton{
                    right: 3.5%;
                }
    
                .swiper{
                    width: 90%;
                }
            }
    
            .partners{
    
                .leftButton{
                    left: 3.5%;
                }
    
                .rightButton{
                    right: 3.5%;
                }
    
                .swiper{
                    width: 90%;
                }
            }
    
            .earnYourTerms{
    
                .leftButton{
                    left: 3.5%;
                }
    
                .rightButton{
                    right: 3.5%;
                }
    
                .swiper{
                    width: 90%;
                }
            }
    
            .appDownloadPromotion{
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 1100px){
    .Farmer {
    
        .Container {
    
            .Testimonials{
    
                .leftButton{
                    top: 170px;
                }
    
                .rightButton{
                    top: 170px;
                }
    
                .swiper{
    
                    .swiper-slide{
                        .IconContainer{
                            width: 320px;
                        }
                    }
                }
            }
    
            .earnYourTerms{
    
                .leftButton{
                    bottom: 40%;
                }
    
                .rightButton{
                    bottom: 40%;
                }
    
                .swiper{
    
                    .swiper-slide{
                        
                        .IconContainer{
                            width: 320px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .Farmer {
    
        .Container {
    
            .HOne {
    
                :nth-child(1) {
                    font-size: 25px;
                }
    
                :nth-child(2) {
                    font-size: 18px;
                }
            }
    
            .POne {
                font-size: 14px;
            }
    
            .Boxes {
                flex-direction: column;
                gap: 30px;
    
                .Box {
    
                    .sub-heading {
                        font-size: 14px;
                    }
                }
            }
    
            .greenBannerSection {
    
                p {
                    font-weight: bold;
                    font-size: 22px;
                }
    
                .boxes {
    
                    .box {
    
                        .box-left {
                            width: 26px;
                            height: 26px;
                        }
    
                        .box-right {
                            font-size: 16px;
                        }
                    }
                }
            }
    
            .send-otp-box {
                margin-bottom: 60px;
                padding: 30px;
                border-radius: 30px;
                gap: 80px;

                .left{
                    width: 400px;

                    iframe{
                        width: 100%;
                    }
                }
    
                .right {
                    gap: 20px;
    
                    .firstline {
                        font-size: 18px;
                    }
                }
            }
    
            .register-process {
    
                p {
                    font-size: 24px;
                }
    
                .middle-container {
                    align-items: center;
                    flex-direction: column;
                    gap: 30px;
                }
            }
    
            .Testimonials{
                width: 80%;
    
                .leftButton{
                    left: 2px;
                }
    
                .rightButton{
                    right: 2px;
                }
    
                .swiper{
                    width: 96%;
    
                    .swiper-slide{
                        .IconContainer{
                            width: 300px;
                        }
                    }
                }
            }
    
            .partners{
    
                .leftButton{
                    left: 2px;
                }
    
                .rightButton{
                    right: 2px;
                }
    
                .swiper{
                    width: 96%;
                }
            }
    
            .earnYourTerms{
    
                .leftButton{
                    left: 2px;
                    bottom: 40%;
                }
    
                .rightButton{
                    right: 2px;
                    bottom: 40%;
                }
    
                .swiper{
                    width: 96%;
    
                    .swiper-slide{
                        
                        .IconContainer{
                            width: 300px;
                        }
                    }
                }
            }
    
            .appDownloadPromotion{
                width: 96%;
    
                .mainHeading{
                    font-size: 26px;
                }
    
                .subHeading{
                    font-size: 16px;
                }

                .image{
                    right: 6%;
                }
            }
        }
    }
}

@media screen and (max-width: 850px){
    .Farmer {
    
        .Container {
    
            .send-otp-box {
                gap: 10px;
                flex-direction: column;

                .left{
                    width: 400px;

                    iframe{
                        width: 100%;
                    }
                }
    
                .right {
                    gap: 20px;
                    align-items: center;
    
                    .firstline {
                        font-size: 18px;
                    }
                }
            }
    
            .register-process {
    
                p {
                    font-size: 24px;
                }
    
                .middle-container {
                    align-items: center;
                    flex-direction: column;
                    gap: 30px;

                    .Box{
                        width: 90%;
                    }
                }
            }
    
            .Testimonials{
    
                .leftButton{
                    top: auto;
                    bottom: 30%;
                    left: 2px;
                }
    
                .rightButton{
                    top: auto;
                    bottom: 30%;
                    right: 2px;
                }
            }
    
            .partners{

                .swiper{
                width: 80%;}
    
                .leftButton{
                    left: 7%;
                }
    
                .rightButton{
                    right: 7%;
                }
            }
    
            .earnYourTerms{
                width: 80%;
            }
    
            .appDownloadPromotion{
                width: 90%;
    
                .mainHeading{
                    font-size: 26px;
                }
    
                .subHeading{
                    font-size: 16px;
                }

                .image{
                    width: 250px;
                    top: 40%;
                    right: 16%;
                }
            }
        }
    }
}

@media screen and (max-width: 500px){
    .Farmer {
    
        .Container {

            .HOne {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                :nth-child(1) {
                    font-size: 25px;
                }
    
                :nth-child(2) {
                    font-size: 18px;
                }
            }
    
            .POne {
                font-size: 14px;
            }
    
            .Boxes {
                flex-direction: column;
                gap: 30px;
    
                .Box {
    
                    .sub-heading {
                        font-size: 14px;
                    }
                }
            }
    
            .greenBannerSection {
    
                p {
                    font-weight: bold;
                    font-size: 22px;
                }
    
                .boxes {
                    width: 100%;
                    justify-content: space-evenly;
    
                    .box {
                        gap: 10px;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
    
                        .box-left {
                            width: 30px;
                            height: 30px;
                        }
    
                        .box-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
    
                            .first-line {
                                font-size: 10px;
                                font-weight: bold;
                            }
                            .second-line{
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
    
            .send-otp-box {
                flex-direction: column;

                .left{
                    width: 100%;

                    iframe{
                        width: 100%;
                    }
                }
    
                .right {
                    gap: 10px;
                    align-items: center;
    
                    .firstline {
                        font-size: 18px;
                    }
                }
            }
    
            .register-process {
    
                p {
                    font-size: 20px;
                }
    
                .middle-container {
                    align-items: center;
                    flex-direction: column;
                    gap: 30px;
                }
            }
    
            .Testimonials{
    
                .leftButton{
                    top: auto;
                    bottom: 30%;
                    left: 2px;
                }
    
                .rightButton{
                    top: auto;
                    bottom: 30%;
                    right: 2px;
                }
            }
    
            .partners{

                .swiper{
                width: 75%;}
    
                // .leftButton{
                //     left: 2px;
                // }
    
                // .rightButton{
                //     right: 2px;
                // }
            }
    
            .earnYourTerms{
                width: 80%;
            }
    
            .appDownloadPromotion{
                width: 95%;
    
                .mainHeading{
                    font-size: 22px;
                }
    
                .subHeading{
                    font-size: 16px;
                }
    
                a{
                    margin-left: 40px;
                    margin-top: 20px;
    
                    img{
                        width: 120px;
                    }
                }

                .image{
                    top: 50%;
                    right: 5%;
                }
            }
        }
    }
}

@media screen and (max-width: 450px){
    .Farmer {
        min-height: 100vh;
        max-height: fit-content;
        width: 100%;
        text-align: center;
        overflow: hidden;
    
        .Container {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-direction: column;
    
            .Testimonials{
    
                .swiper{
    
                    .swiper-slide{
                        .IconContainer{
                            width: 300px;
                        }
                    }
                }
            }
    
            .earnYourTerms{
    
                .swiper{
    
                    .swiper-slide{
                        
                        .IconContainer{
                            width: 300px;
                        }
    
                    }
                }
            }
        }
    }
}