.solutionsSection {
    background: linear-gradient(180deg, #000000 0%,#04063e 50%, #101332 100%);
    padding: 5rem 1rem;
  }
  
  
  .container {
    max-width: 80rem;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2.25rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1.5rem;
  }
  
  .description {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.125rem;
    max-width: 64rem;
    margin-bottom: 4rem;
  }
  
  .cardGrid {
    display: grid;
    gap: 1.5rem;
  }
  
  .card {
    border-radius: 1.5rem;
    padding: 2rem;
    
      transition: background-color 0.3s, color 0.3s;
  }
 
  
  .cardTitle, .cardDescription, .exploreButton {
    transition: color 0.2s, 
    background-color 0.3s;
  }
  
  .card:hover .exploreButton {
    background-color: white; /* Button background becomes white */
    color: #04063e; /* Button text becomes dark */
  }
  .card:hover .cardDescription {
    color: white; /* Explicitly set the description text to white */
  }
  .card:hover .arrowIcon {
    fill: #04063e; /* Arrow icon color changes */
  }
  .card:hover {
    background: linear-gradient(185deg, #11369c, #090b49);
    color: white;
  }
  .cardMvp {
    background: linear-gradient(to bottom right, #e0d5ff, #d5b3ff);
  }
  /* .cardMvp:hover {
    background: linear-gradient(to bottom right, #0f0234, #14046d);
  } */
  .cardRedesign {
    background: linear-gradient(to bottom right, #f2e6ff, #e6ccff);
  }
  
  .cardExtension {
    background: linear-gradient(to bottom right, #ccff00, #e6ff99);
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3rem;
  }
  
  .cardLabel {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .cardIcon {
    width: 2.5rem;
    height: 2.5rem;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .cardTitle {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .cardDescription {
    color: #1a1a1a;
    margin-bottom: 2rem;
  }
  
  .exploreButton {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .exploreButtonDark {
    background-color: black;
    color: white;
  }
  
  .exploreButtonDark:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .exploreButtonLight {
    background-color: white;
    color: black;
  }
  
  .exploreButtonLight:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .arrowIcon {
    width: 1rem;
    height: 1rem;
  }
  
  @media (min-width: 768px) {
    .title {
      font-size: 3rem;
    }
  
    .description {
      font-size: 1.25rem;
    }
  
    .cardGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .title {
      font-size: 3.75rem;
    }
  }
  
  