.ctaSection {
    padding: 4rem 1rem;
    background-color: #f4f5ef;
}
  
  .ctaContainer {
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    border-radius: 1.5rem;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .ctaContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 32rem;
  }
  
  .ctaTitle {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: #111827;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .ctaSubtitle {
    font-size: 2.25rem;
    color: #374151;
  }
  
  .ctaDescription {
    font-size: 1.125rem;
    color: #6B7280;
    line-height: 1.75;
  }
  
  .ctaButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    background-color: #111827;
    color: white;
    font-weight: 500;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
  }
  
  .ctaButton:hover {
    background-color: #1f2937;
  }
  
  .ctaImageWrapper {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .ctaImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
  
  @media (min-width: 768px) {
    .ctaContainer {
      flex-direction: row;
      align-items: center;
      padding: 4rem;
      gap: 4rem;
    }
  
    .ctaContent {
      flex: 1;
    }
  
    .ctaImageWrapper {
      flex: 1;
    }
  
    .ctaTitle {
      font-size: 3rem;
    }
  
    .ctaSubtitle {
      font-size: 2.5rem;
    }
  }
  
  