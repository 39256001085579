.container {
  padding: 4rem 2rem;
  background: linear-gradient(to bottom, #0a0a1a, #1a1a2a);
  min-height: 100vh;
}

.header {
  max-width: 1200px;
  margin: 0 auto 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  max-width: 600px;
  line-height: 1.2;
}

.customWrapper {
  position: relative;
  display: inline-block;
  color: #c4ff3e;
}

.underlineImg {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
}





.highlight {
  position: relative;
  color: #c4ff3e;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 0.2em;
  left: 0;
  width: 100%;
  height: 0.2em;
  background-color: #c4ff3e;
  opacity: 0.3;
  z-index: -1;
}

.borderHighlight {
  position: relative;
}

.borderHighlight::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 2px;
  background-color: #c4ff3e;
  border-radius: 1px;
}

.moreButton {
  padding: 0.75rem 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2rem;
  color: white;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.moreButton:hover {
  background: rgba(255, 255, 255, 0.1);
}

.card {
  background: #4c5bdf;
  border-radius: 1.5rem;
  padding: 3rem;
  color: white;
  max-width: 1200px;
  margin: 0 auto;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 3rem;
}

.imageGrid {
  display: grid;
  gap: 1rem;
}

.images{
  border-radius: 0.75rem;
  width: 100%;
  height: 70vh;
  object-fit: cover;
}
.image {
  border-radius: 0.75rem;
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tags {
  display: flex;
  gap: 0.75rem;
  margin-top: 2rem;
}

.tag {
  padding: 0.5rem 1.25rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  font-size: 0.875rem;
}

.description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.caseButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #1a1a2a;
  border-radius: 2rem;
  color: white;
  transition: all 0.2s ease;
  width: fit-content;
  margin-top: auto;
}

.caseButton:hover {
  background: #2a2a3a;
}

.logo {
  height: 30px;
  width: auto;
}

.mobileScreens {
  border-radius: 0.75rem;
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .cardContent {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .title {
    font-size: 2.5rem;
  }
  
  .header {
    flex-direction: column;
    gap: 1.5rem;
  }
}

