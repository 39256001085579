.TeamComponent{
    width: 100%;
    padding: 40px 10%;

    h1{
        text-align: center;
    }

    .container{
        margin-top: 20px;
        width: 100%;
        height: 76vh;
        border: 2px solid #AB0F0C;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-around;

        img{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        .inactiveProfile{
            min-width: 210px;
            width: 20vw;
            min-height: 260px;
            height: 50vh;
            border-radius: 8px;
            background-color: rgba(white, 0.1);
            position: relative;
            opacity: 75%;
            border: 1px solid white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                border-radius: 8px;
                z-index: -1;
            }

            p {
                margin-top: auto;
                width: 100%;
                text-align: center;
                color: white;
                background-color: rgba(black, 0.5);
                border-radius: 0px 0px 8px 8px;
                padding: 8px 0px;
                font-family: bold;
                font-size: 20px;
            }
        }

        .activeProfile{
            min-width: 240px;
            width: 25vw;
            min-height: 300px;
            height: 60vh;
            border-radius: 8px;
            background-color: rgba(white, 0.1);
            position: relative;
            border: 1px solid white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                border-radius: 8px;
                z-index: -1;
            }

            p {
                margin-top: auto;
                width: 100%;
                text-align: center;
                color: white;
                background-color: rgba(black, 0.5);
                border-radius: 0px 0px 8px 8px;
                padding: 8px 0px;
                font-family: bold;
                font-size: 20px;
            }
        }
    }
}