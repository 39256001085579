// .login-page {
//   min-height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #020617;
//   padding: 1rem;
// }

// .login-card {
//   width: 100%;
//   max-width: 28rem;
//   background-color: rgba(15, 23, 42, 0.5);
//   border-color: #1e293b;
//   color: #ffffff;
//   border-radius: 0.5rem;
// }

// .login-card__header {
//   padding: 1.5rem;
// }

// .login-card__header .title {
//   font-size: 1.5rem;
//   font-weight: bold;
//   margin-bottom: 0.5rem;
// }

// .login-card__header .description {
//   color: #94a3b8;
// }

// .login-card__header .description a {
//   color: #c084fc;
//   transition: color 0.3s ease;
// }

// .login-card__header .description a:hover {
//   color: #d8b4fe;
// }

// .login-card__content {
//   padding: 0 1.5rem;
// }

// .login-card__footer {
//   padding: 1.5rem;
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// }

// .tabs {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// }

// .tabs__list {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 0.5rem;
//   background-color: #1e293b;
//   padding: 0.25rem;
//   border-radius: 0.5rem;
// }

// .tabs__list button {
//   padding: 0.5rem;
//   background: none;
//   border: none;
//   color: #94a3b8;
//   border-radius: 0.25rem;
//   transition: all 0.3s ease;
// }

// .tabs__list button[aria-selected="true"] {
//   background-color: #0f172a;
//   color: #ffffff;
// }

// .tabs__content {
//   display: none;
// }

// .tabs__content[aria-hidden="false"] {
//   display: block;
// }

// .form {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// }

// .input-group {
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;
// }

// .input-group label {
//   font-size: 0.875rem;
//   color: #ffffff;
// }

// .input-wrapper {
//   position: relative;
// }

// .input-wrapper input,
// .input-wrapper select {
//   width: 100%;
//   padding: 0.5rem 1rem;
//   background-color: #1e293b;
//   border: 1px solid #334155;
//   color: #ffffff;
//   border-radius: 0.375rem;
// }

// .input-wrapper input::placeholder,
// .input-wrapper select::placeholder {
//   color: #94a3b8;
// }

// .input-wrapper input:focus,
// .input-wrapper select:focus {
//   outline: 2px solid #9333ea;
//   border-color: #9333ea;
// }

// .password-toggle {
//   position: absolute;
//   right: 0.5rem;
//   top: 50%;
//   transform: translateY(-50%);
//   background: none;
//   border: none;
//   color: #94a3b8;
//   cursor: pointer;
//   padding: 0.25rem;
// }

// .password-toggle:hover {
//   color: #ffffff;
// }

// .submit-button {
//   width: 100%;
//   padding: 0.5rem;
//   background-color: #9333ea;
//   color: #ffffff;
//   border: none;
//   border-radius: 0.375rem;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
// }

// .submit-button:hover {
//   background-color: #a855f7;
// }

// .divider {
//   position: relative;
//   width: 100%;
//   margin: 1rem 0;
// }

// .divider::before {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: 0;
//   right: 0;
//   height: 1px;
//   background-color: #334155;
// }

// .divider-text {
//   position: relative;
//   display: flex;
//   justify-content: center;
// }

// .divider-text span {
//   background-color: #0f172a;
//   padding: 0 0.5rem;
//   color: #94a3b8;
//   font-size: 0.75rem;
//   text-transform: uppercase;
// }

// .social-button {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 0.5rem;
//   padding: 0.5rem;
//   background: none;
//   border: 1px solid #334155;
//   color: #ffffff;
//   border-radius: 0.375rem;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
// }

// .social-button:hover {
//   background-color: #1e293b;
// }

// .social-button svg {
//   width: 1.25rem;
//   height: 1.25rem;
// }
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.hero {
  padding-right: 40px;
}

.hero h1 {
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #2d2d2d;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0 0 60px 0;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  font-size: 18px;
  color: #2d2d2d;
}

.check-icon {
  color: #12b886;
}

.trusted-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.trusted-section h2 {
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
  text-align: center;
}

.logo-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  opacity: 0.7;
}

.logo-grid img {
  height: 70px;
  object-fit: contain;
}

.signup-form {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.google-button {
  width: 100%;
  padding: 12px;
  background: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.divider {
  text-align: center;
  margin: 20px 0;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  margin-bottom: 20px;

}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #2d2d2d;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.required {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
}

.terms {
  font-size: 14px;
  color: #666;
  margin: 20px 0;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background: #aa0f0b;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.login-link a {
  color: #4285f4;
  text-decoration: none;
}



