.MiniTractorPage {
    width: 100%;

    .banner {
        height: 80vh;
        width: 100%;
        position: relative;
        color: white;
        display: flex;
        align-items: center;

        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: rgba($color: black, $alpha: 0.3);
        }

        img {
            width: 100%;
            height: 100%;
            z-index: -2;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        .page-heading {
            width: 80%;
            margin: auto auto;
            color: white;

            .text-container {
                width: 100%;

                    .hO {
                        font-size: 3rem;
                        font-weight: bold;
                        line-height: 60px;
                    }

                    .hF {
                        font-size: 1.2rem;
                    }

                    @media screen and (max-width: 1200px) {
                        .hO {
                            font-size: 1.5rem;
                            line-height: 30px;
                        }

                        .hF {
                            font-size: 1.125rem;
                        }
                    }
                    }
        }
    }

    .BookingStepper {
        width: 100%;
        padding: 40px 10%;
        display: flex;
        flex-direction: column;
        // gap: 30px;

        h3 {
            max-width: 700px;
            width: 100%;
            font-size: 32px;
            font-weight: bold;
            margin: 0px auto;
            text-align: center;
        }

        .scrolling-container {
            width: 100%;
            height: 400px;
            overflow: auto;

            .container {
                width: 100%;
                display: flex;
                gap: 20px;
                position: sticky;
                top: 12vh;
                height: 100%;

                .left {
                    width: 50%;
                    height: 100%;
                    background-color: white;

                    .left-heading{
                        font-size: 26px;
                        font-weight: 600;
                    }

                    .details{
                        margin-top: 20px;
                        font-size: 20px;
                    }
                }

                .right {
                    width: 50%;
                    height: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        // max-width: 400px;
                        height: 300px;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 20px;
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 1000px) {
    .MiniTractorPage {
        .banner {

            .pageHeading {
                width: 90%;
            }
        }

        .BookingStepper {
            padding: 40px 5%;
        }
    }
}

@media screen and (max-width: 900px) {
    .MiniTractorPage {

        .BookingStepper {

            h3 {
                font-size: 26px;
            }

            .scrolling-container{
                height: 500px;

                .container{
                    height: fit-content;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: white;

                    .left{
                        height: 200px;
                        width: 100%;

                        .details{
                            font-size: 14px;
                        }
                    }

                    .right{
                        width: 100%;

                        img{
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}