.ReunionesComunitrates {
    width: 100%;

    .banner {
        height: 80vh;
        width: 100%;
        position: relative;

        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -2;
        }

        .shadow {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: linear-gradient(to right, rgba(50, 50, 50, 1), rgba(50, 50, 50, 0));
        }

        .bannerTextContainer {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .bannerText {
                width: 80%;
                margin: auto;
                color: white;
                display: flex;
                flex-direction: column;
                gap: 10px;

                :nth-child(1) {
                    font-size: 24px;
                    font-weight: 500;
                    color: #b8babc;
                }

                :nth-child(2) {
                    font-size: 56px;
                    font-weight: 700;
                }

                button {
                    padding:10px 20px;
                    border: 1px solid #AB0F0C;
                    border-radius: 9999px;
                    background-color: transparent;
                    outline: none;
                    width: fit-content;
                    font-size: 20px;
                    color: #AB0F0C;
                    font-weight: 700;
                }
            }
        }
    }

    .SecondComponent {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f2f3f8;

        .container {
            width: 80%;
            margin: auto;

            h2 {
                font-size: 60px;
                font-weight: 600;
                width: 600px;
            }

            p {
                font-size: 22px;
                width: 700px;
                margin-top: 20px;
            }

            .grid-container {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                gap: 20px;
                margin-top: 30px;

                .box {
                    width: 100%;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    padding-left: 15px;
                    padding-right: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    color: white;
                    background-color: #0b0b0b;
                    border-radius: 16px;
                    transition: all 500ms;
                    cursor: pointer;

                    .box-top {
                        width: 66px;
                        height: 66px;
                        background-color: #1e1b24;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 500ms;

                        img {
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                        }
                    }

                    .box-heading {
                        font-size: 32px;
                        font-weight: 700;
                    }

                    @media screen and (max-width: 1680px) {
                        .box-heading {
                            font-size: 28px;
                        }
                    }

                    @media screen and (max-width: 1440px) {
                        .box-heading {
                            font-size: 26px;
                        }
                    }

                    @media screen and (max-width: 1366px) {
                        .box-heading {
                            font-size: 22px;
                        }
                    }

                    @media screen and (max-width: 1280px) {
                        .box-heading {
                            font-size: 20px;
                        }
                    }

                    @media screen and (max-width: 912px) {
                        .box-heading {
                            font-size: 18px;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        .box-heading {
                            font-size: 16px;
                        }
                    }

                    @media screen and (max-width: 650px) {
                        .box-heading {
                            font-size: 15px;
                        }
                    }

                    &:hover {
                        background-color: #005dff;

                        .box-top {
                            background-color: #1d6ffb;
                        }
                    }
                }
            }

            @media screen and (max-width: 1680px) {
                h2 {
                    font-size: 52px;
                }

                p {
                    font-size: 18px;
                }

                .box {

                    .box-heading {
                        font-size: 28px;
                    }
                }

                .grid-container{
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
            }

            @media screen and (max-width: 1440px) {
                h2 {
                    font-size: 50px;
                }

                p {
                    font-size: 17px;
                }

                .box {

                    .box-heading {
                        font-size: 26px;
                    }
                }
            }

            @media screen and (max-width: 1366px) {
                .box {

                    .box-heading {
                        font-size: 22px;
                    }
                }
            }

            @media screen and (max-width: 1280px) {
                h2 {
                    font-size: 46px;
                }

                .box {

                    .box-heading {
                        font-size: 20px;
                    }
                }
            }

            @media screen and (max-width: 1200px) {
                h2 {
                    font-size: 40px;
                }

                p {
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 1000px) {
                .grid-container{
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }

            @media screen and (max-width: 912px) {
                h2 {
                    font-size: 32px;
                }

                .box {

                    .box-heading {
                        font-size: 18px;
                    }
                }
            }

            @media screen and (max-width: 820px) {
                h2 {
                    font-size: 30px;
                }

                p {
                    width: 100%;
                }
            }

            @media screen and (max-width: 767px) {
                h2 {
                    font-size: 27px;
                    width: 100%;
                }

                .box {

                    .box-heading {
                        font-size: 16px;
                    }
                }

                .grid-container{
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }

            @media screen and (max-width: 650px) {
                h2 {
                    font-size: 26px;
                    font-weight: 500;
                }

                .box {

                    .box-heading {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .ThirdComponent{
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #0f0f0f;

        .container{
            width: 80%;
            margin: auto;
            display: flex;
            align-items: center;
            gap: 20px;

            .left{
                width: 50%;
                color: white;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 30px;
                color white

                h2{
                    font-size: 45px;
                    font-weight: 600;
                }

                p{
                    font-size: 22px;
                }

                button{
                    background: transparent;
                    border: 1px solid white;
                    padding: 10px;
                    border-radius: 8px;
                    outline: none;
                    color: white;
                }

                @media screen and (max-width: 1680px){
                    h2{
                        font-size: 36px;
                    }
                }
                @media screen and (max-width: 1440px){
                    h2{
                        font-size: 35px;
                    }
                }
            }

            .right{
                width: 50%;

                img{
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .ReunionesComunitrates {
        .banner {
            .bannerTextContainer {
                .bannerText {
                    width: 90%;

                    :nth-child(2) {
                        font-size: 26px;
                    }
                }
            }
        }

        .SecondComponent {
            .container {
                width: 90%;
            }
        }

        .ThirdComponent{
            .container{
                width: 90%;
                flex-direction: column;

                .left{
                    width: 100%;
                }
                .right{
                    width: 100%;
                }
            }
        }
    }
}