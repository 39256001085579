.creativity-section{
    width: 100%;
    height: fit-content;
    padding: 40px 10%;
    text-align: center;
    background-color: #f2f3f8;
    h2{
        font-weight: 700;
        padding: 10px 0px;
        span{
            color: #AB0F0C;
        }
    }
    p{
        font-size: 14px;
        font-weight: 500;
        color: rgb(75, 75, 75);
    }
    .creativity-container{
        display: flex;
        align-items: center;
        gap: 20px;
        .creativity-left{
            width: 50%;
            text-align: left;
            .d-flex{
                display: flex;
                margin: 20px 0px;
                align-items: center;
                gap: 15px;
                }
                .text{
                    h3{
                        font-weight: 600;
                        padding: 3px;
                    }
                    p{
                        // width: 70%;
                        font-size: 13px;
                        line-height: 22px;
                    }
                }
            }
        }
        .creativity-right{
            // flex: 1;
            width: 50%;
            text-align: center;
            img{
                width: 100%;
                border-radius: 10px;
            }
        }
    }

    @media screen and (max-width: 1000px){
        .creativity-section{
            padding: 40px 5%;
            .creativity-container{
                flex-direction: column;
                .creativity-left{
                    width: 100%;
                    padding: 0px;
                }
                .creativity-right{
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }