.PrivacyPolicy{
    min-height: 100vh;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .Container{
        width: 60%;
        height: 100%;

        .HOne{
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            // background: blue;
            font-weight: bold;
            text-align: center;
            font-size: 20px;
        }

        p{
            font-size: 14px;

            .HTwo{
                font-weight: bold;
                font-size: 18px;
                padding-top: 16px;
                padding-bottom: 4px;

            }

            span{
                color: blue;
                text-decoration: underline;
            }
        }

        .Ul{
            margin-left: 30px;
            padding-top: 10px;
            padding-bottom: 10px;

            li{
                font-size: 14px;  
            }
        }

        div{
            ul{
                margin-left: 30px;
                padding-top: 10px;
    
                li{
                    font-size: 14px;  
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .PrivacyPolicy{
        .Container{
            width: 95%;
        }
    }
}