.container {
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.visionSection {
  background-color: #f7fafc;
  padding: 4rem 2rem;
  height: 60vh;
  display: flex; /* Enables Flexbox */
  flex-direction: column; /* Align items in a column */
  justify-content: center; /* Centers items vertically */
  align-items: center; /* Centers items horizontally */
  text-align: center; /* Ensures text is centered */
}


.visionContainer {
  max-width: 60rem;
  margin: 0 auto;
}

.visionTitle {
  text-align: left;
  font-size: 2.5rem;
  font-weight: 700;
  color: #2d3748;
  margin-bottom: 1rem;
}

.visionText {
  font-size: 1.125rem;
  color: #4a5568;
  text-align: left;
  line-height: 1.75;
}
.heroShowcaseSectionmain{
  background-color: #f4f5ef;

}
.heroShowcaseSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.heroContent {
  max-width: 48rem;
  margin-bottom: 2rem;
}

.heroTitle {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: normal;
  color: #1a202c;
  margin-bottom: 2rem;
}

.heroDescription {
  font-size: 1.25rem;
  color: #4a5568;
  margin-bottom: 2rem;
}

.stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 20rem;
  margin-bottom: 2rem;
}

.statNumber {
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.statLabel {
  color: #4a5568;
}

.showcaseImageContainer {
  width: 100%;
  max-width: 300px;
  margin-top: 2rem;
}

.showcaseImage {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.showcaseButton {
  background-color: black;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  margin-top: 3rem;
}

.showcaseButton:hover {
  background-color: #2d3748;
}

.scrollToTop {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 0.75rem;
  background-color: black;
  color: white;
  border-radius: 9999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.scrollToTop:hover {
  background-color: #2d3748;
}

.framerBadge {
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
}

.framerLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #4a5568;
  transition: color 0.3s ease;
  text-decoration: none;
}

.framerLink:hover {
  color: #1a202c;
}

@media (min-width: 768px) {
  .heroShowcaseSection {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .heroContent {
    flex: 1;
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .showcaseImageContainer {
    flex: 1;
    margin-top: 0;
  }

  .heroTitle {
    font-size: 3.75rem;
  }
}

