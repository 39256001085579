.Service {
    width: 100%;
    overflow: hidden;

    .Container {

        .video-section {
            width: 100%;
            height: 88vh;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2pc;

            video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }

            p {
                color: white;
                font-size: 4.5rem;
                font-weight: 600;
                margin-left: 200px;
                width: 75%;
            }

            .button {
                margin-left: 200px;
            }
        }

        .services-section {
            background-color: #f2f3f8;
            width: 100%;
            padding: 40px 10%;
            max-height: fit-content;
            color: black;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .heading-banner {
                font-weight: 600;
                font-size: 36px;
            }

            .service-options {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                gap: 40px;

                .o-box-container {
                    background-size: cover;
                    width: 100%;
                    height: 308px;
                    background-blend-mode: multiply;
                    background-color: rgba(156, 163, 175);
                    transition: all 500ms;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;

                    &:hover {
                        background-color: rgba(243 244 246);
                        transform: translateY(-16px);
                    }
                }
            }

            .view-more-button {
                display: flex;
                align-items: center;
                align-self: center;
                border: 1px solid black;
                padding: 14px 20px;
                border-radius: 9999px;
                gap: 10px;
                cursor: pointer;
                transition: all 500ms;

                :nth-child(2) {
                    transition: all 500ms;
                }

                &:hover {
                    color: white;
                    background-color: black;

                    :nth-child(2) {
                        transform: translateY(-3px);
                    }
                }
            }
        }

        .ServiceFooter {
            width: 100%;
            padding: 40px 10%;
            height: fit-content;
            background-color: #f2f3f8;
            display: flex;
            align-items: center;
            gap: 20px;

            .left {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    // height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .right {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;

                .headingOne {
                    color: black;
                    font-weight: 500;
                    font-size: 30px;
                    width: 80%;
                }

                .subHeading {
                    color: black;
                    font-size: 20px;
                    width: 80%;
                    text-align: start;
                }

                .button {
                    padding: 10px 20px;
                    background: #AB0F0C;
                    color: white;
                    cursor: pointer;
                    border-radius: 10px;
                }
            }
        }

        .SeventhComponent {
            width: 100%;
            background-color: #AB0F0C;
            padding: 40px 10%;

            .seventh_container {
                width: 100%;
                background-color: rgba(white, 0.1);
                padding: 48px 20px;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                gap: 40px;

                h1 {
                    color: white;
                    text-align: center;
                }

                .data_container {
                    width: 100%;
                    display: grid;
                    gap: 32px;
                    grid-template-columns: repeat(2, 1fr);

                    .data_box {
                        width: 100%;
                        max-width: 560px;
                        margin: 0px auto;
                        display: flex;
                        gap: 16px;

                        .data_text_container {
                            font-size: 22px;
                            color: white;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                    }
                }
            }
        }

        .FifthComponent {
            width: 100%;
            padding: 40px 10%;
            background-color: white;

            h2 {
                font-size: 36px;
                color: black;
                font-weight: bold;
                text-align: center;
            }

            .Fifth_container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 32px;
                margin-top: 30px;

                .container_left {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        border-radius: 12px;
                    }
                }

                .container_right {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .right_container {
                        width: 100%;
                        color: black;

                        .map_container {
                            width: 100%;
                            display: flex;
                            gap: 16px;
                            align-items: center;
                            position: relative;
                            padding-bottom: 40px;

                            &::before {
                                position: absolute;
                                left: 28px;
                                height: 100%;
                                width: 4px;
                                background-color: rgba(black, 20);
                            }

                            h4 {
                                font-weight: bold;
                                font-size: 24px;
                                transition: all 500ms;
                            }
                        }

                        .map_container_alt {
                            width: 100%;
                            display: flex;
                            gap: 16px;
                            align-items: center;
                            position: relative;
                            padding-bottom: 40px;

                            h4 {
                                font-weight: bold;
                                font-size: 24px;
                                transition: all 500ms;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .Service {

        .Container {

            .video-section {

                p {
                    font-size: 40px;
                    margin-left: 60px;
                }

                .button {
                    margin-left: 60px;
                }
            }

            .services-section {
                .service-options {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .Service {

        .Container {

            .services-section {
                padding: 40px 5%;
            }

            .ServiceFooter {
                padding: 40px 5%;

                .left {
                    display: none;
                }

                .right {
                    flex: 1;
                }
            }

            .SeventhComponent {
                padding: 40px 5%;
            }

            .FifthComponent {
                padding: 40px 5%;

                .Fifth_container {
                    gap: 20px;
                    flex-direction: column;

                    .container_left {
                        width: 100%;
                        gap: 20px;
                    }

                    .container_right {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .Service {

        .Container {

            .video-section {

                p {
                    font-size: 32px;
                    margin-left: 60px;
                }

                .button {
                    margin-left: 60px;
                }
            }

            .services-section {
                .service-options {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .Service {

        .Container {

            .services-section {

                .service-options {

                    .o-box-container {
                        height: 280px;

                        span {
                            position: absolute;
                            top: 16px;
                            left: 12px;
                            font-size: 20px;
                        }
                    }
                }


            }

            .SeventhComponent {
                .seventh_container {
                    .data_container {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .Service {

        .Container {

            .video-section {

                p {
                    font-size: 26px;
                    margin-left: 20px;
                }

                .button {
                    margin-left: 20px;
                }
            }

            .services-section {
                .service-options {
                    grid-template-columns: repeat(1, minmax(0, 1fr));

                    .o-box-container {

                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .Service {

        .Container {

            .ServiceFooter {

                .right {
                    flex: 1;

                    .headingOne {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}