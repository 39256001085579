.TermsAndCondition{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;

    .Container{
        width: 80%;

        .heading{
            font-size: 30px;
            line-height: 36px;
            font-weight: 600;
            margin: 10px 0px;
        }

        p{
            margin: 20px 20px;
        }

        ul{
            margin: 20px 70px;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
}