.post-property {
    width: 80%;
    padding: 40px 0px;
    margin: 0px auto;

    p {
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        color: rgb(154, 154, 154);
    }

    .heading {
        width: 100%;
        text-align: center;
        margin: 10px auto;
        font-size: 32px;
    }

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;

        .left {
            width: 50%;
            text-align: right;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .right {
            width: 50%;

            span {
                font-size: 12px;
                color: rgb(145, 144, 144);
                font-weight: 700;
            }

            h2 {
                font-size: 30px;
                line-height: 40px;

                span {
                    font-size: 15px;
                    background-color: #219653;
                    padding: 5px 10px;
                    color: white;
                    border-radius: 5px;
                    font-weight: 700;
                }
            }

            p {
                line-height: 20px;
                color: black;
                text-align: left;
                margin: 10px 0px;
                font-size: 14px;
                line-height: 23px;
                font-weight: 500;
                color: #54637a;
            }

            .p-btn {
                margin: 10px auto;

                button {
                    border: none;
                    padding: 10px 10px;
                    font-size: 16px;
                    font-weight: 600;
                    color: white;
                    background-color: #AB0F0C;
                    border-radius: 3px;
                    cursor: pointer;
                }
            }
        }
    }
}

// -------------------- Responsive Style -----------------

@media screen and (max-width: 1000px) {
    .post-property {
        width: 90%;

        .heading {
            font-size: 25px;
        }

        .container {
            flex-direction: column;

            .left {
                width: 100%;
            }

            .right {
                width: 100%;
                text-align: center;

                h2 {
                    width: 100%;
                }

                p {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}