.container {
  background: linear-gradient(135deg, #004d40 0%, #001524 100%);
    color: white;
  padding: 4rem 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  max-width: 800px;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 600;
  line-height: 1.2;
}

.highlight {
  color: #c5f82a;
  display: inline;
}

.navigationWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
padding: 0 3rem;
}

.navigation {
  display: flex;
  gap: 1rem;
}

.navButton {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.navButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.content {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .content {
    grid-template-columns: 1fr 1fr;
  }
}

.videoContainer {
  position: relative;
  aspect-ratio: 16/9;
  background-color: #1a1a1a;
  border-radius: 0.5rem;
  overflow: hidden;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.playButton:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.testimonial {
  background-color: white;
  color: black;
  padding: 2rem;
  border-radius: 0.5rem;
}

.author {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.authorImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.authorInfo h3 {
  font-weight: 600;
  margin: 0;
}

.authorInfo p {
  color: #666;
  margin: 0;
}

.rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.quote {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.caseStudyButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f5f5f5;
  color: black;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.caseStudyButton:hover {
  background-color: #e5e5e5;
}