.ServicesTractores {
    width: 100%;
    min-height: 100vh;
    max-width: fit-content;

    .Banner {
        width: 100%;
        padding: 40px 0px;
        min-height: 90vh;
        overflow: hidden;
        position: relative;
        background-image: linear-gradient(to right, #180056, #AB0F0C, #591f07);
        display: flex;
        align-items: center;
        justify-content: center;

        .banner_container {
            width: 80%;
            margin: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            .banner_left_container {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .text-container {
                    background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0.8));
                    background-clip: text;
                    -webkit-background-clip: text;
                    color: transparent;

                    h1 {
                        font-size: 3rem;
                        font-weight: bold;
                        line-height: 60px;
                        color: transparent;
                    }

                    h4 {
                        color: transparent;
                        font-size: 1.2rem;
                    }

                    @media screen and (max-width: 1200px) {
                        h1 {
                            font-size: 1.5rem;
                            line-height: 30px;
                        }

                        h4 {
                            font-size: 1.125rem;
                        }
                    }
                }
            }

            .banner_right_container {
                width: 50%;
                height: 100%;
                position: relative;

                .text_container {
                    position: absolute;
                    top: 0px;
                    left: 32px;
                    width: 275px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 20px;

                    .map_container {
                        background-image: linear-gradient(to right, #1b005d, #300986);
                        padding: 30px;
                        border-radius: 8px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        h2 {
                            font-size: 2.25rem;
                            background-image: linear-gradient(to right, #FACC69, #FA75F8);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                        }
                    }
                }

                img {
                    border-radius: 8px;
                    height: auto;
                    max-height: 80vh;
                    width: 100%;
                    margin-left: 10%;
                    object-fit: cover;
                }

                @media screen and (max-width: 1200px) {
                    .text_container {

                        .map_container {
                            padding: 20px;

                            h2 {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }

                @media screen and (max-width: 1100px) {
                    .text_container {
                        width: 200px;
                    }
                }

                @media screen and (max-width: 1100px) {
                    .text_container {
                        width: fit-content;
                    }
                }
            }
        }
    }

    .creativity-section {
        width: 100%;
        height: fit-content;
        padding: 40px 10%;
        text-align: center;
        background-color: #f2f3f8;

        h2 {
            font-weight: 700;
            padding: 10px 0px;

            span {
                color: #AB0F0C;
            }
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: rgb(75, 75, 75);
        }

        .creativity-container {
            display: flex;
            align-items: center;
            gap: 20px;

            .creativity-left {
                width: 50%;
                text-align: left;

                .d-flex {
                    display: flex;
                    margin: 20px 0px;
                    align-items: center;
                    gap: 15px;
                }

                .text {
                    h3 {
                        font-weight: 600;
                        padding: 3px;
                    }

                    p {
                        // width: 70%;
                        font-size: 13px;
                        line-height: 22px;
                    }
                }
            }
        }

        .creativity-right {
            // flex: 1;
            width: 50%;
            text-align: center;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }
    }

    .SecondComponent {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;

        .headings {
            width: 80%;
            margin: auto;
            margin-bottom: 20px;

            h2 {
                font-weight: 600;
                font-size: 52px;
            }

            p {
                font-size: 18px;
                margin-top: 16px;
            }

            @media screen and (max-width: 1680px) {
                h2 {
                    font-size: 52px;
                }

                p {
                    font-size: 18px;
                }
            }

            @media screen and (max-width: 1440px) {
                h2 {
                    font-size: 50px;
                }

                p {
                    font-size: 17px;
                }
            }

            @media screen and (max-width: 1280px) {
                h2 {
                    font-size: 46px;
                }

                p {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1200px) {
                h2 {
                    font-size: 40px;
                }

                p {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1024px) {
                h2 {
                    font-size: 36px;
                }

                p {
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 912px) {
                h2 {
                    font-size: 32px;
                }
            }

            @media screen and (max-width: 650px) {
                h2 {
                    font-size: 22px;
                }
            }
        }

        .Container {
            height: fit-content;
            width: 80%;
            margin: auto;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #AB0F0C;
            display: flex;
            box-sizing: border-box;

            .right-container {
                padding: 50px;
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .contents {
                    width: 100%;
                    height: fit-content;
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    gap: 26px;

                    img {
                        width: 60px;
                    }

                    .Heading {
                        font-weight: 600;
                        font-size: 32px;
                    }

                    .Paragraph {
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
            }

            .left-container {
                box-sizing: border-box;
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 100px;
                padding-bottom: 80px;
                width: 50%;
                height: 100%;
                background: linear-gradient(90deg, rgba(171, 15, 12, 1) 43%, rgba(184, 38, 9, 0.9808298319327731) 81%, rgba(200, 98, 70, 0.9472163865546218) 100%);

                .list-container {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: rgba(255, 255, 255, 0.497);
                        border-radius: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: white;
                        /* Color of the thumb */
                        border-radius: 5px;
                        /* Rounded corners of the thumb */
                    }

                    .box {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        cursor: pointer;

                        .heading {
                            color: white;
                            font-size: 20px;
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }

                        .box-bottom {
                            width: calc(100% - 60px);
                            margin-left: 30px;
                            height: 1px;
                        }
                    }
                }
            }
        }
    }

    .ThirdComponent {
        width: 100%;
        padding: 40px 0px;
        background-color: #f2f3f8;
        background-color: #AB0F0C;

        .container {
            width: 80%;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 30px;
            color: white;

            .mainHeading {
                font-weight: 600;
                font-size: 56px;
                width: 80%;
            }

            .subHeading {
                font-size: 22px;
                width: 80%;
            }

            .elementsContainer {
                width: 100%;
                display: flex;
                gap: 20px;

                .left {
                    width: 50%;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }

                .right {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 20px;

                    .box {
                        display: flex;
                        gap: 20px;

                        .box-left {
                            min-width: 40px;
                            max-width: 40px;
                            min-height: 40px;
                            max-height: 40px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #AB0F0C;
                        }

                        .box-right {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            .heading {
                                font-size: 22px;
                                font-weight: 700;
                            }

                            .details {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 1680px) {
                .mainHeading {
                    font-size: 52px;
                }
            }

            @media screen and (max-width: 1440px) {
                .mainHeading {
                    font-size: 50px;
                }
            }

            @media screen and (max-width: 1280px) {
                .mainHeading {
                    font-size: 46px;
                }
            }

            @media screen and (max-width: 1200px) {
                .mainHeading {
                    font-size: 40px;
                }
            }

            @media screen and (max-width: 1024px) {
                .mainHeading {
                    font-size: 36px;
                }

                .subHeading {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 912px) {
                .mainHeading {
                    font-size: 32px;
                }
            }

            @media screen and (max-width: 820px) {
                .mainHeading {
                    font-size: 30px;
                }
            }

            @media screen and (max-width: 767px) {
                .mainHeading {
                    font-size: 27px;
                }
            }

            @media screen and (max-width: 650px) {
                .mainHeading {
                    font-size: 22px;
                    font-weight: 500;
                }

                .elementsContainer {

                    .right {

                        .box {

                            .box-right {

                                .heading {
                                    font-size: 18px;
                                    font-weight: 500;
                                }

                                .details {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .ServicesTractores {

        .Banner {

            .banner_container {
                width: 90%;
                flex-direction: column;

                .banner_left_container {
                    width: 100%;
                }

                .banner_right_container {
                    width: 100%;

                    .text_container {
                        left: -5%;
                        gap: 8px;

                        .map_container {
                            gap: 8px;
                        }
                    }

                    img {
                        margin-left: 5%;
                    }
                }
            }
        }

        .creativity-section {
            padding: 40px 5%;

            .creativity-container {
                flex-direction: column;

                .creativity-left {
                    width: 100%;
                    padding: 0px;
                }

                .creativity-right {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .SecondComponent {
            .headings {
                width: 90%;
            }

            .Container {
                width: 90%;
                flex-direction: column-reverse;
                height: fit-content;

                .left-container {
                    width: 100%;
                }

                .right-container {
                    width: 100%;
                }
            }
        }

        .ThirdComponent {
            .container {
                width: 90%;

                .mainHeading {
                    width: 100%;
                }

                .subHeading {
                    width: 100%;
                }

                .elementsContainer {
                    flex-direction: column;

                    .left {
                        width: 100%;
                    }

                    .right {
                        width: 100%;
                    }
                }
            }
        }
    }

}