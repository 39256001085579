.VehicleInformation{
    width: 100%;
    background-color: #f2f3f8;
    padding-top: 40px;
    padding-bottom: 40px;

    .container{
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .headings{
            font-size: 28px;
            font-weight: 600;
        }

        .below-headings-section{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .sub-heading{
                font-size: 14px;
                color: rgb(122, 122, 122);
                font-weight: 500;
            }

            .scrolling-button-section{
                padding-right: 40px;
                display: flex;
                align-items: center;
                gap: 20px;

                .Button{
                    width: 40px;
                    height: 40px;
                    background-color: white;
                    border-radius: 999px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px, rgba(51, 51, 51, 0.05) 0px 0px 4px;

                    &:nth-child(1){
                        width: 40px;
                    }
                }
            }

        }

        .image-container{
            width: 100%;
            // display: grid;
            // grid-template-columns: repeat(3, minmax(0, 1fr));
            display: flex;
            overflow-x: scroll;
            scroll-behavior: smooth;
            transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
            gap: 30px;

            .image-box{
                min-width: 31%;
                max-width: 31%;
                // min-width: 400px;
                // max-width: 400px;
                height: 500px;
                border-radius: 20px;
                overflow: hidden;
                position: relative;

                &:hover{
                    .link-button{
                        transition: all 500ms;
                        opacity: 1;
                    }
                }

                img{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                }

                .link-button{
                    padding: 10px 20px;
                    background-color: rgba(#000000, 0.7);
                    z-index: 10;
                    color: white;
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    translate: -50%;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    transition: all 500ms;
                    opacity: 0;
                }
            }
        }

        @media screen and (max-width: 1200px){
            .image-container{
                .image-box{
                min-width: 48%;
                max-width: 48%;
                }
            }
        }

        @media screen and (max-width: 767px){
            .headings{
                font-size: 32px;
            }

            .sub-heading{
                font-size: 20px;
            }

            .image-container{
                .image-box{
                min-width: 96%;
                max-width: 96%;
                height: 500px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .VehicleInformation{
        .container{
            width: 90%;
        }
    }
}