.LoanApplication{
    width: 80%;
    margin: 20px auto;

    h1 {
        text-align: center;
    }

    .addressSection {
        margin-top: 40px;
        margin-bottom: 40px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        input {
            outline: none;
            width: 100%;
            background-color: transparent;
            border: 1px solid rgba(gray, 0.6);
            padding: 10px;
            border-radius: 8px;

            &:focus {
                border: 1px solid rgba(blue, 0.4); 
            }
        }

        select {
            outline: none;
            width: 100%;
            background-color: transparent;
            border: 1px solid rgba(gray, 0.6);
            padding: 10px;
            border-radius: 8px;

            &:focus {
                border: 1px solid rgba(blue, 0.4); 
            }
        }
    }

    p{
        font-size: 20px;
        font-weight: 600;
        color: #747474;
    }

    button {
        outline: none;
        border: none;
        background-color: #AB0F0C;
        color: white;
        font-size: 18px;
        font-weight: 600;
        padding: 8px 20px;
        border-radius: 999px;
        cursor: pointer;
        margin-top: 20px;
    }
}