.AgriculturalTractors{
    width: 100%;
    overflow: hidden;

    .Banner {
        width: 100%;
        min-height: 88vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2pc;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        h1 {
            color: white;
            font-size: 4.5rem;
            font-weight: 600;
            margin: 0px auto;
            width: 80%;
        }

        .paragraphOne {
            font-size: 24px;
            color: white;
            margin: 0px auto;
            width: 80%;
        }

        @media screen and (max-width: 767px) {

            .paragraphOne {
                font-size: 20px;
            }
        }
    }

    .FirstComponent {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;

        .headings{
            width: 80%;
            margin: auto;
            margin-bottom: 20px;

            h2{
                font-weight: 600;
                font-size: 52px;
            }

            p{
                font-size: 18px;
                margin-top: 16px;
            }

            @media screen and (max-width: 1680px){
                h2{
                    font-size: 52px;
                }

                p{
                    font-size: 18px;
                }
            }

            @media screen and (max-width: 1440px){
                h2{
                    font-size: 50px;
                }
                p{
                    font-size: 17px;
                }
            }

            @media screen and (max-width: 1280px){
                h2{
                    font-size: 46px;
                }
                p{
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1200px){
                h2{
                    font-size: 40px;
                }
                p{
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1024px){
                h2{
                    font-size: 36px;
                }
                p{
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 912px){
                h2{
                    font-size: 32px;
                }
            }

            @media screen and (max-width: 650px){
                h2{
                    font-size: 22px;
                }
            }
        }

        .Container {
            height: fit-content;
            width: 80%;
            margin: auto;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #AB0F0C;
            display: flex;
            box-sizing: border-box;

            .right-container {
                padding: 50px;
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .contents {
                    width: 100%;
                    height: fit-content;
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    gap: 26px;

                    img{
                        width: 60px;
                    }

                    .Heading{
                        font-weight: 600;
                        font-size: 32px;
                    }

                    .Paragraph{
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
            }

            .left-container{
                box-sizing: border-box;
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 100px;
                padding-bottom: 80px;
                width: 50%;
                height: 100%;
                // background-color: #AB0F0C;
                // background: rgb(200,98,70);
// background-image: radial-gradient(circle, rgba(200,98,70,0.9472163865546218) 0%, rgba(171,15,12,1) 48%, rgba(255,46,0,0.9808298319327731),100%);
background: linear-gradient(90deg, rgba(171, 15, 12, 1) 43%, rgba(184, 38, 9, 0.9808298319327731) 81%, rgba(200, 98, 70, 0.9472163865546218) 100%);

                .list-container{
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: rgba(255, 255, 255, 0.497);
                        border-radius: 5px;
                      }
    
                      &::-webkit-scrollbar-thumb {
                        background: white; /* Color of the thumb */
                        border-radius: 5px; /* Rounded corners of the thumb */
                      }

                    .box{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        cursor: pointer;

                        .heading{
                            color: white;
                            font-size: 20px;
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }

                        .box-bottom{
                            width: calc(100% - 60px);
                            margin-left: 30px;
                            height: 1px;
                        }
                    }
                }
            }
        }
    }

    .SecondComponent{
        width: 80%;
        padding: 40px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 0px auto;

        .left{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 100%;
                border-radius: 10px;
            }
        }

        .rigth{
            width: 50%;

            .heading{
                font-weight: 500;
                font-size: 32px;
                margin-bottom: 20px;
            }

            .box-top{
                font-weight: 500px;
                margin-bottom: 20px;
                font-size: 26px;
                width: 100%;
                padding: 8px 10px;
                background-color: #f2f3f8;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                border-radius: 10px 10px 0px 0px;
            }

            .box-bottom{
                transition: all 500ms;
                cursor: pointer;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 8px 10px;
                width: 100%;
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }

    .FourthComponent{
        width: 100%;
        height: 500px;
        background-color: gray;
        background-image: url('https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/contact_background1.webp');
        background-blend-mode: multiply;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 30px;

        .container{
            width: 80%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            color: white;
            margin: 0px auto;
            text-align: center;

            .mainHeading{
                font-size: 32px;
                font-weight: 600;
            }

            .subHeading{
                font-size: 24px;
                font-weight: 600;
            }

            button{
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #AB0F0C;
                color: white;
                outline: none;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .AgriculturalTractors{
        width: 100%;
        overflow: hidden;
    
        .Banner {

            h1 {
                width: 90%;
            }

            .paragraphOne {
                width: 90%;
            }
        }

        .FirstComponent{
            .headings{
                width: 90%;
            }
            .Container{
                width: 90%;
                flex-direction: column-reverse;
                height: fit-content;

                .left-container{
                    width: 100%;
                }

                .right-container{
                    width: 100%;
                }
            }
        }

        .SecondComponent{
            width: 90%;
            flex-direction: column;

            .left{
                width: 100%;

                img{
                    width: 100%;
                }
            }

            .rigth{
                width: 100%;

                .heading{
                    font-size: 22px;
                }

                .box-top{
                    font-size: 18px;
                }

                .box-bottom{
                    font-size: 16px;
                }
            }
        }

        .FourthComponent{
            min-height: 500px;
    
            .container{
                width: 90%;
                margin: 0px auto;
                text-align: center;
    
                .mainHeading{
                    font-size: 22px;
                }
    
                .subHeading{
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .AgriculturalTractors{
        width: 100%;
        overflow: hidden;
    
        .Banner {
            h1{
                font-size: 32px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .AgriculturalTractors{
        width: 100%;
        overflow: hidden;
    
        .Banner {
            h1{
                font-size: 26px;
            }
        }
    }
}