.Farmer {
    width: 100%;
    text-align: center;
    overflow: hidden;

    .Container {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        .topSearchComponent {
            width: 100%;
            height: fit-content;
            position: relative;
            background-color: #AB0F0C;

            .container-top {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;

                .left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 50%;

                    p {
                        width: 70%;
                        color: white;
                        font-weight: 600;
                        font-size: 24px;
                        text-align: start;
                        margin-right: 6%;
                    }
                    p1 {
                        width: 70%;
                        color: white;
                        font-weight: 600;
                        font-size: 30px;
                        text-align: start;
                        margin-right: 6%;
                    }
                }

                .right {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    img {
                        margin-left: 14%;
                        width: 60%;
                        border-radius: 20px;
                    }
                }
            }

        }

        .PartnersOfFarmers {
            padding: 50px 10%;
            width: 100%;
            background-color: #AB0F0C;
            // padding: 50px;

            .mainHeading {
                font-weight: 600;
                font-size: 26px;
                color: white;
                text-align: start;
            }

            .subHeading {
                color: gray;
                text-align: start;
                font-size: 20px;
            }

            .container {
                margin-top: 50px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                gap: 20px;

                .boxContainer {
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    width: fit-content;
                    background: white;
                    padding: 0;
                    box-sizing: border-box;
                    padding: 5px;
                    position: relative;
                    z-index: 0;

                    ::before {
                        content: '';
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        border: 2px solid white;
                    }

                    .left {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        img {
                            width: 60px;
                        }

                        p {
                            font-size: 12px;
                            width: 200px;
                            text-align: left;
                        }

                        p1 {
                            font-size: 18px;
                            width: 200px;
                            text-align: left;
                        }

                        .companyName {
                            display: none;
                        }

                        button {
                            width: 100px;
                            margin-left: auto;
                            margin-right: auto;
                            padding-left: 5px;
                            padding-right: 5px;
                            padding-top: 6px;
                            padding-bottom: 6px;
                            background-color: white;
                            border: 1px solid black;
                            transition: all 500ms;

                            &:hover {
                                background-color: black;
                                color: white;
                            }
                        }
                    }

                    .right {
                        flex: 1;
                        width: fit-content;

                        img {
                            height: 200px;
                            width: 116px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .Benefits {
            width: 100%;
            padding: 40px 10%;
            display: flex;
            position: relative;

            .left {
                position: absolute;
                left: 10%;
                top: 10%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;

                .heading {
                    font-size: 26px;
                    font-weight: 500;
                }

                .details-container {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .span {
                        font-weight: 500;
                        position: relative;
                        z-index: 10;
                    }

                    .span::before {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        height: 8px;
                        width: 100%;
                        background-color: #fff6d0;
                        z-index: -1;
                    }
                }
            }

            .right {

                img {
                    width: 100%;
                }
            }
        }

        .belowBenifits {
            background-color: #AB0F0C;
            color: white;
            width: 100%;
            padding: 40px 10%;
            display: flex;
            gap: 20px;
            justify-content: space-between;

            .belowBenifitsMainContainer {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                :nth-child(1) {
                    font-weight: 600;
                    font-size: 26px;
                }

                :nth-child(2) {
                    font-weight: 400;
                    font-size: 18px;
                }
            }
        }

        .Establishment {
            padding: 40px 10%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .HeadingOne {
                font-size: 30px;
                font-weight: 600;
                width: 100%;
                text-align: left;
            }

            .imageComponent {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 15px;

                .imageContainer {
                    width: 212px;
                    height: 210px;
                    // background-image: linear-gradient(to bottom,rgba(0,0,0,0) 35%,rgba(0,0,0,0.9) 100%);
                    overflow: hidden;
                    border-radius: 8px;
                    position: relative;

                    &:hover {

                        img {
                            transform: scale(1.3);
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        transition: all 500ms;
                    }

                    .tag {
                        position: absolute;
                        bottom: 10%;
                        left: 50%;
                        transform: translate(-50%);
                        font-weight: 600;
                        color: white;
                        font-size: 20px;
                    }
                }
            }

            .HeadingTwo {
                font-size: 30px;
                font-weight: 600;
                width: 100%;
                text-align: left;
                margin-top: 40px;
            }

            .next {
                padding: 9px 12px;
                background-color: white;
                border-radius: 999px;
                position: absolute;
                bottom: 18%;
                right: 8%;
                transform: translate(-0%, -50%);
                border: none;
                filter: drop-shadow(0px 0px 5px #555);
            }

            .prev {
                padding: 9px 12px;
                background-color: white;
                border-radius: 999px;
                position: absolute;
                bottom: 18%;
                left: 8%;
                transform: translate(-0%, -50%);
                z-index: 5;
                border: none;
                filter: drop-shadow(0px 0px 5px #555);
            }

            ::-webkit-scrollbar {
                width: 0px;
            }

            .EstablishmentComponents {
                margin-top: 50px;
                display: flex;
                align-items: center;
                gap: 50px;
                width: 100%;
                overflow-x: scroll;
                scroll-behavior: smooth;

                .component {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;

                    .top {
                        width: 320px;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        border: 1px solid black;
                        justify-content: space-between;

                        .left {
                            margin-left: 20px;
                        }

                        .right {
                            height: 70px;
                            width: 75px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #5e23dc1f;

                            img {
                                width: 80%;
                                height: 80%;
                            }
                        }
                    }
                }
            }
        }

        .News {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 40px 10%;

            .container {
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                position: relative;

                .headingone {
                    font-size: 30px;
                    margin-bottom: 13px;
                }

                ::-webkit-scrollbar {
                    width: 0px;
                }

                .next {
                    padding: 9px 12px;
                    background-color: white;
                    border-radius: 999px;
                    position: absolute;
                    bottom: 34%;
                    right: -1%;
                    transform: translate(-0%, -50%);
                    border: none;
                    filter: drop-shadow(0px 0px 5px #555);
                }

                .prev {
                    padding: 9px 12px;
                    background-color: white;
                    border-radius: 999px;
                    position: absolute;
                    bottom: 34%;
                    left: -1%;
                    transform: translate(-0%, -50%);
                    z-index: 5;
                    border: none;
                    filter: drop-shadow(0px 0px 5px #555);
                }

                .slider {
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    overflow-x: scroll;
                    scroll-behavior: smooth;
                    gap: 20px;

                    .newsBox {
                        width: 328px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .img {
                            width: 328px;
                            height: 156px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                transition: all 500ms;
                            }
                        }

                        h4 {
                            color: gray;
                        }

                        hr {
                            margin-top: 4px;
                            margin-bottom: 4px;
                            width: 100%;
                            height: 1px;
                            background: black;
                        }

                        .newswriterInfo {
                            font-size: 14px;
                            color: gray;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                        }

                        &:hover {
                            img {
                                transform: scale(1.2);
                            }
                        }
                    }
                }
            }
        }

        .PeopleReview {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 40px 10%;
            gap: 30px;

            .left {
                flex: 1;
                // height: 100%;
                height: 700px;
                // height: 80vh;
                width: 420px;

                .leftImage {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .right {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .rightHeading {
                    font-size: 26px;
                    font-weight: 700;
                }

                .rightSubHeading {
                    margin-top: 10px;
                    font-weight: 600;
                }

                .profileSection {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;
                    gap: 40px;

                    img {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .profileSectionRight {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .profileName {
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }

                p {
                    text-align: left;
                    font-weight: 600;
                }

                .arrowSection {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .left {
                        width: 50px;
                        height: 50px;
                        border: 2px solid black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .Farmer {

        .Container {

            .PartnersOfFarmers {

                .container {
                    flex-direction: column;
                }

            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .Farmer {

        .Container {

            .topSearchComponent {

                .container-top {
                    padding: 0px 5%;
                    flex-direction: column;

                    .left {
                        justify-content: flex-start;
                        width: 100%;

                        p {
                            width: 100%;
                            text-align: start;
                        }
                    }

                    .right {
                        width: 100%;

                        img {
                            margin-left: 0%;
                            width: 100%;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .Farmer {

        .Container {

            .PartnersOfFarmers {
                padding: 50px 5%;
            }

            .Benefits {
                padding: 40px 5%;

                .left {
                    left: 5%;
                }
            }

            .belowBenifits {
                padding: 40px 5%;
            }

            .Establishment {
                padding: 40px 5%;
            }

            .News {
                padding: 40px 5%;
            }

            .PeopleReview {
                padding: 40px 5%;

                .left {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .Farmer {

        .Container {

            .topSearchComponent {
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;

                .left {
                    flex: 1;
                    width: 100%;
                    justify-content: center;

                    p {
                        width: 90%;
                        margin-right: 0%;
                    }
                }

                .right {
                    flex: 1;
                    width: 100%;
                    justify-content: center;

                    img {
                        margin-top: 20px;
                        width: 50%;
                        border-radius: 20px;
                        margin-left: 0%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .Farmer {

        .Container {

            .Benefits {

                .left {

                    .heading {
                        font-size: 20px;
                    }

                    .details-container {
                        gap: 10px;

                        .span {
                            font-size: 14px;
                        }

                        .span::before {
                            height: 6px;
                        }
                    }
                }
            }

            .belowBenifits {

                .belowBenifitsMainContainer {

                    :nth-child(1) {
                        font-size: 20px;
                    }

                    :nth-child(2) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .Farmer {

        .Container {

            .Benefits {

                .right {
                    margin-top: 10%;
                }
            }

            .News {

                .container {
                    width: 100%;

                    .headingone {
                        font-size: 22px;
                    }

                    .next {
                        padding: 9px 12px;
                        background-color: white;
                        border-radius: 999px;
                        position: absolute;
                        bottom: 34%;
                        right: 3%;
                        transform: translate(-0%, -50%);
                        border: none;
                        filter: drop-shadow(0px 0px 5px #555);
                    }

                    .prev {
                        padding: 9px 12px;
                        background-color: white;
                        border-radius: 999px;
                        position: absolute;
                        bottom: 34%;
                        left: -1%;
                        transform: translate(-0%, -50%);
                        z-index: 5;
                        border: none;
                        filter: drop-shadow(0px 0px 5px #555);
                    }

                    .slider {

                        .newsBox {
                            width: 280px;

                            img {
                                width: 220px;
                                height: 120px;
                            }

                            h4 {
                                color: gray;
                                font-size: 12px;
                            }

                            h3 {
                                font-style: 16px;
                            }

                            .newswriterInfo {
                                font-size: 10px;
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }

            .PeopleReview {
                height: fit-content;

                .right {

                    .rightHeading {
                        font-size: 20px;
                    }

                    .rightSubHeading {
                        font-size: 14px;
                        text-align: start;
                    }

                    .profileSection {
                        gap: 20px;

                        img {
                            width: 70px;
                            height: 70px;
                        }

                        .profileSectionRight {

                            .profileName {
                                font-size: 16px;
                            }

                            i {
                                font-size: 12px;
                            }
                        }
                    }

                    p {
                        font-size: 12px;
                    }

                    .arrowSection {

                        .left {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .Farmer {

        .Container {

            .Benefits {

                .right {
                    margin-top: 30%;
                }
            }

            .PartnersOfFarmers {

                .container {

                    .boxContainer {
                        flex-direction: column;
                        gap: 20px;

                        .left {
                            width: 100%;
                            gap: 20px;
                        }

                        .right {
                            width: 100%;

                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }

            .belowBenifits {

                .belowBenifitsMainContainer {

                    :nth-child(1) {
                        font-size: 16px;
                    }

                    :nth-child(2) {
                        font-size: 10px;
                    }
                }
            }

            .Establishment {

                .HeadingOne {
                    font-size: 26px;
                    margin-left: 20px;
                }

                .imageComponent {

                    .imageContainer {
                        width: 160px;
                        height: 140px;
                    }
                }

                .HeadingTwo {
                    font-size: 26px;
                    margin-left: 20px;
                }

                .next {
                    display: none;
                }

                .prev {
                    display: none;
                }

                .EstablishmentComponents {
                    flex-direction: column;

                    .component {

                        .top {
                            width: 80vw;

                            .left {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .Farmer {

        .Container {

            .Benefits {

                .right {
                    margin-top: 42%;
                }
            }
        }
    }
}