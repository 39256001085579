.home-middle{
    width: 70%;
    margin: 0px auto;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    color: white;

    .left-middle{
        width: 50%;
        text-align: center;
        iframe{
            border-radius: 10px;
            margin-left: 20px;
        }
    }

    .right-middle{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20px;
        .heading{
            h1{
                font-weight: 600;
            }
            h3{
                font-weight: 500;
                padding: 10px 0px;
                letter-spacing: 0.1px;
                word-spacing: 0.1px;
            }
            p{
                font-size: 12px;
            }
        }
        .middle-btn{
            margin-top: 25px;
         
        //    z-index: 1;
           position: relative;
        }
    }
}

// ------------------ Responsive Style ---------------------

@media screen and (max-width: 1500px) {
    .home-middle{
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .home-middle{
        flex-direction: column;

        .left-middle{
            width: 100%;
            iframe{
                width: 100%; 
                margin: 0px;
                height: 500px;
            }
        }

        .right-middle{
            width: 100%;
        }
    }
}

@media screen and (max-width: 1000px) {
    .home-middle{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
        padding-top: 10px;
        align-items: center;
        justify-content: center;
        gap: 20px;
        overflow: hidden;
        .left-middle{
            iframe{
                width: 100%; 
                margin: 0px;
                height: 400px;
            }
        }
        .right-middle{
            width: 100%;
            align
            h1{
                font-size: 20px;
            }
            h3{
                font-size: 16px;
            }
            .middle-btn{
                padding: 0px 15px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .home-middle{
        .left-middle{
            iframe{
                width: 100%; 
                margin: 0px;
                height: 300px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .home-middle{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
        padding-top: 10px;
        align-items: center;
        justify-content: center;
        .left-middle{
            iframe{
                height: 260px;
            }
        }
        .right-middle{
            width: 100%;
            // margin: 0px 20px;
            margin-top: -30px;
            .middle-btn{
                padding: 0px 15px;
            }
        }
    }
}