@keyframes arrow {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(20px);
    }

    100% {
        transform: translateX(0px);
    }
}

.arrowIcon {
    animation: arrow 1500ms infinite;
}

.booking {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    .Hero {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .left {
            color: white;
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 0px 10%;

            .headingContainer {
                display: flex;
                flex-direction: column;

                h1 {
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 48.76px;
                    white-space: nowrap;

                    .dive {
                        font-size: 46px;
                        font-weight: 700;
                        line-height: 55.2px;
                        color: transparent;
                        background: linear-gradient(to right, #FF8356, #AF582A);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }

                    .vr {
                        font-size: 46px;
                        font-weight: 700;
                        line-height: 56.07px;
                        color: transparent;
                        background: linear-gradient(to right, #C0B7E8, #8176AF);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }

            p {
                font-size: 16px;
            }

            .buttonSection {
                display: flex;
                align-items: center;
                gap: 16px;

                button {
                    background: linear-gradient(90deg, #7D2323 0%, #FB3842 100%);
                    font-weight: 700;
                    outline: none;
                    border: none;
                    border-radius: 999px;
                    padding: 12px 30px;
                    color: white;
                    cursor: pointer;
                }
            }
        }

        .right {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 10;

            .spider {
                width: 100%;
                height: auto;
                max-height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
            }

            .image_container {
                width: 524px;
                height: 455px;
                border-radius: 100px 100px 100px 240px;
                background: #00000021;
                padding: 16px;
                z-index: 1;

                .image_box {
                    width: 100%;
                    height: 100%;
                    border-radius: 100px 100px 100px 240px;

                    .banner {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px 100px 100px 240px;
                    }
                }
            }
        }
    }

    .Visit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .resContainer {
            display: none;
            background: radial-gradient(50% 2900.76% at 50% 53.89%, rgba(79, 15, 12, 0.95) 0%, #99615C 100%);
            box-shadow: 0px 4px 4px 0px #C0B7E803;
            max-width: 90%;
            width: fit-content;
            height: 100px;
            margin: 8px auto;
            padding: 22px 30px;
            border-radius: 999px;

            .visitBox {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;

                img {
                    width: 70px;
                    height: auto;
                    object-fit: cover;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    color: white;

                    .headingOne {
                        font-weight: 700;
                        font-size: 24px;
                    }

                    .headingTwo {
                        font-size: 14px;
                    }
                }
            }
        }

        .container {
            background: radial-gradient(50% 2900.76% at 50% 53.89%, rgba(79, 15, 12, 0.95) 0%, #99615C 100%);
            box-shadow: 0px 4px 4px 0px #C0B7E803;
            width: 80%;
            height: 100px;
            margin: 20px auto;
            padding: 22px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 999px;

            .visitBox {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;

                img {
                    width: 70px;
                    height: auto;
                    object-fit: cover;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    color: white;

                    .headingOne {
                        font-weight: 700;
                        font-size: 24px;
                    }

                    .headingTwo {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .about {
        width: 100%;

        .container {
            width: 80%;
            margin: auto;
            color: white;
            display: flex;
            gap: 40px;
            margin-top: 60px;

            .left {
                width: 50%;

                h2 {
                    font-weight: 700;
                    font-size: 36px;
                }

                .subHeading {
                    font-weight: 300;
                    font-size: 36px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }

                img {
                    width: 524px;
                    height: 557px;
                    border-radius: 100px 240px 100px 240px;
                    object-fit: cover;
                }
            }

            .right {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 30px;

                p {
                    font-size: 16px;
                }

                .headingContainer {
                    h2 {
                        font-weight: 700;
                        font-size: 36px;
                    }

                    .subHeading {
                        font-weight: 300;
                        font-size: 36px;
                    }
                }

                button {
                    font-weight: 700;
                    outline: none;
                    border: none;
                    border-radius: 999px;
                    padding: 12px 30px;
                    color: white;
                    cursor: pointer;
                    width: fit-content;
                    background: linear-gradient(90deg, #AC2424 0%, #D12E32 47.5%, #FB3842 100%);
                }
            }
        }
    }

    .service {
        width: 100%;

        .container {
            width: 80%;
            margin: auto;
            color: white;
            display: flex;
            gap: 40px;
            margin-top: 60px;

            .left {
                width: 50%;

                h2 {
                    font-weight: 700;
                    font-size: 36px;
                }

                .subHeading {
                    font-weight: 300;
                    font-size: 36px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }
            }

            .right {
                width: 50%;

                p {
                    font-size: 16px;
                }
            }
        }

        .imagecontainer {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 40px auto;

            .below-headings-section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: end;

                .scrolling-button-section {
                    padding-right: 40px;
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .Button {
                        width: 40px;
                        height: 40px;
                        background-color: white;
                        border-radius: 999px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px, rgba(51, 51, 51, 0.05) 0px 0px 4px;

                        &:nth-child(1) {
                            width: 40px;
                        }
                    }
                }

            }

            .image-container {
                width: 100%;
                // display: grid;
                // grid-template-columns: repeat(3, minmax(0, 1fr));
                display: flex;
                overflow-x: scroll;
                scroll-behavior: smooth;
                transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
                gap: 10px;
                color: white;
                justify-content: center;

                .image-box {
                    min-width: 200px;
                    max-width: 25%;
                    min-height: 380px;
                    max-height: fit-content;
                    border-radius: 20px;
                    overflow: hidden;
                    position: relative;
                    background-image: radial-gradient(#BD2618, #4D1213);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    padding: 20px;

                    .imageBg {
                        width: 120px;
                        height: 120px;
                        border-radius: 999px;
                        background-color: rgba(#4A1717, 0.3);
                        padding: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 999px;
                        }
                    }

                    h2 {
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                    }

                    p {
                        font-size: 12px;
                        text-align: center;
                    }

                    button {
                        font-weight: 700;
                        outline: none;
                        border: none;
                        border-radius: 999px;
                        padding: 12px 30px;
                        color: white;
                        cursor: pointer;
                        width: fit-content;
                        background: linear-gradient(90deg, #AC2424 0%, #D12E32 47.5%, #FB3842 100%);
                    }
                }
            }
        }

        .technologyContainer {
            width: 80%;
            margin: 80px auto;
            height: 200px;
            border-radius: 999px;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 999px;
                z-index: 1;
            }

            .wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 2;
                background-color: rgba(#211E2E, 0.7);
                border-radius: 999px;
            }

            .content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;
                align-items: center;
                justify-content: center;
                color: white;
                z-index: 3;

                h2 {
                    font-size: 36px;
                    font-weight: 700;
                    text-align: center;
                }

                h3 {
                    font-size: 36px;
                    font-weight: 300;
                    text-align: center;
                }
            }

            .arrowbutton {
                width: 100px;
                height: 100px;
                border-radius: 999px;
                background-color: rgba(#4A1717, 0.3);
                padding: 15px;
                position: absolute;
                z-index: 4;
                bottom: -50px;
                left: 50%;
                transform: translateX(-50%);

                .arrowcontainer {
                    width: 100%;
                    height: 100%;
                    border-radius: 999px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: linear-gradient(#C0B7E8, #8176AF);
                    color: #AB0F0C;
                }
            }
        }

        .techIcons {
            width: 80%;
            margin: 40px auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap;

            img {
                width: 166px;
                height: 166px;
                object-fit: cover;
            }
        }
    }

    .process {
        width: 100%;

        .container {
            width: 80%;
            margin: auto;
            color: white;
            display: flex;
            gap: 40px;
            margin-top: 60px;

            .left {
                width: 50%;

                h2 {
                    font-weight: 700;
                    font-size: 36px;
                }

                .subHeading {
                    font-weight: 300;
                    font-size: 36px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }
            }

            .right {
                width: 50%;

                p {
                    font-size: 16px;
                }
            }
        }

        .imagecontainer {
            width: 100%;
            display: flex;
            gap: 10px;
            justify-content: space-between;
            margin: 40px auto;
            padding: 0px 10%;
            position: relative;
            z-index: 10;

            .processImage {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                object-fit: cover;
                z-index: -1;
            }

            .box {
                width: 200px;

                .bg-wrapper {
                    width: 200px;
                    height: 200px;
                    box-sizing: border-box;
                    border-radius: 999px;
                    background-color: rgba(#0D0D0D, 0.3);
                    padding: 12px;

                    .num-content {
                        width: 100%;
                        height: 100%;
                        background-image: linear-gradient(#E3574A, #8E2B2B);
                        color: white;
                        font-size: 64px;
                        font-weight: bold;
                        border-radius: 999px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .content {
                    display: flex;
                    gap: 6px;

                    .arrow {
                        color: white;
                    }

                    p {
                        font-weight: bold;
                        font-size: 24px;
                        color: white;
                    }
                }
            }
        }

        .imagecontainerres {
            width: 90%;
            display: none;
            flex-direction: column;
            gap: 10px;
            margin: 40px auto;

            .below-headings-section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: end;

                .scrolling-button-section {
                    padding-right: 40px;
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .Button {
                        width: 40px;
                        height: 40px;
                        background-color: white;
                        border-radius: 999px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px, rgba(51, 51, 51, 0.05) 0px 0px 4px;

                        &:nth-child(1) {
                            width: 40px;
                        }
                    }
                }

            }

            .image-container {
                width: 100%;
                // display: grid;
                // grid-template-columns: repeat(3, minmax(0, 1fr));
                display: flex;
                overflow-x: scroll;
                scroll-behavior: smooth;
                transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
                gap: 10px;
                color: white;

                .box {
                    min-width: 200px;
                    max-width: 200px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    .bg-wrapper {
                        width: 120px;
                        height: 120px;
                        box-sizing: border-box;
                        border-radius: 999px;
                        background-color: rgba(#0D0D0D, 0.3);
                        padding: 12px;

                        .num-content {
                            width: 100%;
                            height: 100%;
                            background-image: linear-gradient(#E3574A, #8E2B2B);
                            color: white;
                            font-size: 42px;
                            font-weight: bold;
                            border-radius: 999px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .content {
                        display: flex;
                        gap: 6px;

                        .arrow {
                            color: white;
                        }

                        p {
                            font-weight: bold;
                            font-size: 18px;
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .connect {
        width: 80%;
        margin: 40px auto;
        padding: 30px;
        border-radius: 20px;
        background-image: radial-gradient(#692209, #4E110F);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        color: white;

        h1 {
            font-size: 36px;
            font-weight: 700;
        }

        hr {
            width: 400px;
            height: 2px;
            border-radius: 999px;
            background-image: linear-gradient(#C94A4A, #E8C1B7, #Af7876, #4A160D);
        }

        h3 {
            font-size: 36px;
            font-weight: 300;
        }

        .inputs {
            width: 100%;
            display: flex;
            align-items: center;
            color: white;
            gap: 20px;

            input {
                width: 50%;
                outline: none;
                border: 2px solid white;
                padding: 12px 26px;
                background: transparent;
                color: white;
                border-radius: 999px;
            }

            select {
                width: 50%;
                outline: none;
                border: 2px solid white;
                padding: 12px 26px;
                background: transparent;
                border-radius: 999px;
                color: rgba($color: gray, $alpha: 1.0)
            }
        }

        input {
            width: 100%;
            outline: none;
            border: 2px solid white;
            padding: 12px 26px;
            background: transparent;
            color: white;
            border-radius: 999px;
        }

        textarea {
            width: 100%;
            outline: none;
            border: 2px solid white;
            padding: 12px 26px;
            background: transparent;
            color: white;
            resize: none;
            height: 80px;
            border-radius: 999px;
        }

        button {
            font-weight: 700;
            outline: none;
            border: none;
            border-radius: 999px;
            padding: 12px 30px;
            color: white;
            cursor: pointer;
            width: fit-content;
            background: linear-gradient(90deg, #AC2424 0%, #D12E32 47.5%, #FB3842 100%);
        }
    }
}

@media screen and (max-width: 1200px) {
    .booking {
        .Hero {
            .left {
                padding: 0px 5%;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .booking {
        .Hero {
            flex-direction: column-reverse;
            min-height: 100vh;
            max-height: fit-content;
            gap: 40px;

            .left {
                width: 100%;
                padding: 0px 5%;
                display: flex;
                align-items: center;
                justify-content: center;

                .headingContainer {
                    text-align: center;
                }

                p {
                    text-align: center;
                }

                .buttonSection {
                    justify-content: center;
                    margin-bottom: 30px;
                }
            }

            .right {
                width: 100%;
            }
        }

        .Visit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .container {
                display: none;
            }

            .resContainer {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .about {
            .container {
                width: 90%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .left {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    h2 {
                        text-align: center;
                    }

                    .subHeading {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .right {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        text-align: center;
                    }

                    .headingContainer {
                        h2 {
                            text-align: center;
                        }

                        .subHeading {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .service {
            .container {
                width: 90%;
                flex-direction: column;
                align-items: center;
                justify-content: center;


                .left {
                    width: 100%;

                    h2 {
                        text-align: center;
                    }

                    .subHeading {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .right {
                    width: 100%;

                    p {
                        text-align: center;
                    }
                }
            }

            .imagecontainer {
                width: 90%;
            }

            .technologyContainer {
                width: 90%;
            }

            .techIcons {
                width: 90%
            }
        }

        .process {
            .container {
                width: 90%;
                flex-direction: column;
                align-items: center;
                justify-content: center;


                .left {
                    width: 100%;

                    h2 {
                        text-align: center;
                    }

                    .subHeading {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .right {
                    width: 100%;

                    p {
                        text-align: center;
                    }
                }
            }

            .imagecontainer {
                display: none;
            }

            .imagecontainerres {
                display: flex;
            }
        }

        .connect {
            width: 90%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .arrowIcon {
        display: none;
    }

    .booking {
        .Hero {

            .left {

                .headingContainer {
                    h1 {
                        font-size: 30px;

                        .dive {
                            font-size: 30px;
                        }

                        .vr {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .Visit {
            .resContainer {
                display: flex;
                align-items: center;
                justify-content: center;

                .visitBox {
                    img {
                        width: 50px;
                    }

                    .right {
                        .headingOne {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .about {
            .container {
                .left {
                    h2 {
                        font-size: 26px;
                    }

                    .subHeading {
                        font-size: 26px;
                    }
                }

                .right {
                    .headingContainer {
                        h2 {
                            font-size: 26px;
                        }

                        .subHeading {
                            font-size: 26px;
                        }
                    }
                }
            }
        }

        .service {
            .container {
                .left {
                    h2 {
                        font-size: 26px;
                    }

                    .subHeading {
                        font-size: 26px;
                    }
                }
            }

            .imagecontainer {
                .image-container {
                    justify-content: start;

                    .image-box {
                        h2 {
                            font-size: 18px;
                        }
                    }
                }
            }

            .technologyContainer {
                .content {
                    h2 {
                        font-size: 26px;
                    }

                    h3 {
                        font-size: 26px;
                    }
                }
            }

            .techIcons {
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .process {
            .container {
                .left {
                    h2 {
                        font-size: 26px;
                    }

                    .subHeading {
                        font-size: 26px;
                    }
                }
            }
        }

        .connect {
            h1 {
                font-size: 26px;
            }

            h3 {
                font-size: 26px;
            }

            .inputs {
                flex-direction: column;

                input {
                    width: 100%;
                }

                select {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .booking {
        .Hero {
            .right {
                .image_container {
                    width: 400px;
                    height: 370px;
                }
            }
        }

        .about {
            .container {
                .left {
                    img {
                        width: 400px;
                        height: 400px;
                        border-radius: 50px 120px 50px 120px;
                    }
                }
            }
        }

        .service {
            .technologyContainer {
                .content {
                    h2 {
                        font-size: 22px;
                    }

                    h3 {
                        font-size: 22px;
                    }
                }
            }
        }

        .connect {
            hr {
                width: 100%
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .booking {
        .Hero {
            .right {
                .image_container {
                    width: 300px;
                    height: 260px;
                }
            }
        }

        .about {
            .container {
                .left {
                    img {
                        width: 300px;
                        height: 300px;
                        border-radius: 50px 120px 50px 120px;
                    }
                }
            }
        }
    }
}