.app-main {
  width: 100%;
  padding: 40px 0px;
  .app-download {
    width: 80%;
    height: 50vh;
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    background-color: #f5eee4;
    margin: 0px auto;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 10px;
    .left-container {
      width: 50%;
      margin-top: 10px;
      h2 {
        display: flex;
        // align-items: center;
        flex-direction: column;
        line-height: 30px;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #555;
        }
      }
      .line-one,
      .line-two {
        display: flex;
        align-items: center;
        margin: 20px 0px;
        gap: 5px;
        h3 {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .get-app {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 100px;
        }
      }
    }
    .right-container {
      width: 50%;
      height: 100%;
      padding: 0px;
      background-image: linear-gradient(
          rgba(245, 238, 228, 0.4),
          rgba(245, 238, 228, 0.56)
        ),
        url("../../assets/left-img.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      text-align: center;
      img {
        margin-top: -59px;
        width: 70%;
        // height: 100%;
      }
    }
  }
}

// ---------------- Responsive Style ------------------

@media screen and (max-width: 1000px){
  .app-main {
    .app-download {
      width: 90%;
      height: fit-content;
      flex-direction: column;

      .left-container {
        width: 100%;
        margin-top: 0px;
      }
      .right-container {
        width: 100%;
        height: 100%;
        img {
          margin-top: 0px;
          width: 100%;
          // height: 100%;
        }
      }
    }
  }
}

