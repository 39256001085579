.split-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f5ef;
    padding: 30px;
  }
  
  .left-section {
    padding: 2rem;
    position: sticky;
    top: 0;
    height: 100vh;
  }
  
  .left-section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .left-section .description {
    color: #666;
    margin-bottom: 3rem;
  }
  
  .left-section .stats {
    display: flex;
    gap: 4rem;
  }
  
  .left-section .stats h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .left-section .stats p {
    color: #666;
  }
  
  .right-section {
    background-color: #f4f5ef;
  }
  
  .content-card {
    min-height: 100vh;
    padding: 2rem;
  }
  
  .card-content {
    position: sticky;
    top: 4rem;
  }
  
  .card-image {
    border-radius: 0.75rem;
    overflow: hidden;
    margin-bottom: 2rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .card-image img {
    width: 100%;
    height: 50vh;
  }
  
  .content-card h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .content-card p {
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .view-work-btn {
    background-color: #000;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-work-btn:hover {
    background-color: #333;
  }
  
  @media (min-width: 1024px) {
    .split-layout {
      flex-direction: row;
    }
  
    .left-section {
      width: 50%;
      padding: 4rem;
    }
  
    .left-section h1 {
      font-size: 3.5rem;
    }
  
    .right-section {
      width: 50%;
    }
  
    .content-card {
      padding: 4rem;
    }
  
    .content-card h2 {
      font-size: 1.875rem;
    }
  }
  
  