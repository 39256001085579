.footer-section {
  width: 100%;
  height: fit-content;
  background-color: #191919;
  padding: 40px 0px;
  color: white;
  .container {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    // align-items: center;
    .footer-one {
      flex: 6;
      width: 100%;
      justify-content: center;
      .footer-logo {
        width: 150px;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
      .logo-two {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 150px;
          cursor: pointer;
          &:last-child {
            width: 120px;
          }
        }
      }
      .socials {
        // background-color: red;
        margin-left: 10px;
        p {
          font-weight: 500;
        }
        .social-icons {
          margin-top: 10px;
          margin-bottom: 10px;
          display: flex;
          gap: 10px;
          cursor: pointer;
        }
      }
      .copyright {
        margin-top: 5px;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #919191;
        // background-color: yellow;
      }
    }
    .footer-two {
      flex: 3;
      h4 {
        font-weight: 600;
        font-size: 13px;
      }
      ul {
        li {
          list-style: none;
          margin: 5px 0px;
          a {
            color: #919191;
            font-size: 12px;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }
    .footer-three {
      flex: 3;
      h4 {
        font-weight: 600;
        font-size: 13px;
      }
      ul {
        li {
          list-style: none;
          margin: 5px 0px;
          a {
            color: #919191;
            font-size: 12px;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }
    .footer-four {
      flex: 3;
      h4 {
        font-weight: 600;
        font-size: 13px;
      }
      ul {
        li {
          list-style: none;
          margin: 5px 0px;
          a {
            color: #919191;
            font-size: 12px;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }
    .footer-five {
      flex: 4;
      h4 {
        font-weight: 600;
        font-size: 13px;
      }
      .pstore-logo {
        margin-top: 10px;
        img {
          width: 100px;
          object-fit: cover;
          margin: 5px;
          cursor: pointer;
        }
      }
      .scanner{
        // text-align: center;
        img{
            width: 50%;
        }
        p{  margin-top: 5px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
        }
    }
    }
  }
}

// -------------------- Responsive Design ---------------
@media screen and (max-width: 1000px) {
    .footer-section{
        width: 100%;
        height: 100%;
        .container{
          width: 90%;
            flex-direction: column;
            .footer-one{
                margin: 10px 0px;
                padding: 5px 0px;
                border-bottom: 1px solid lightgray;
            }
            .footer-two{
                margin: 10px 0px;
                padding: 5px 0px;
                border-bottom: 1px solid lightgray;
            }
            .footer-three{
                padding: 5px 0px;
                margin: 10px 0px;
                border-bottom: 1px solid lightgray;
            }
            .footer-four{
                padding: 5px 0px;
                margin: 10px 0px;
                border-bottom: 1px solid lightgray;
            }
            .footer-five{
                padding: 5px 0px;
                margin: 10px 0px;
                border-bottom: 1px solid lightgray;
                
            }
        }
    }

}