*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.homePage{
    width: 100%;
    height: fit-content;
    background-color: #AB0F0C;
    background-position:100%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.5s;
    position: relative;
    
}

@media screen and (max-width: 1000px){
    .homePage{
        width: 100%;
        z-index: 2;
    
    }
}
@media screen and (max-width: 480px){
    .homePage{
        width: 100%;
        z-index: 2;
        // overflow: hidden;
    
    }
}