.faqSection {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #141441, #0d0a36);
    padding: 5rem 1rem;
  }
  
  .container {
    max-width: 64rem;
    width: 100%;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .accordionContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .accordionItem {
    background-color: rgba(26, 26, 46, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .accordionTrigger {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.5rem;
    background: none;
    border: none;
    color: white;
    text-align: left;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .accordionTrigger:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .chevronContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    transition: transform 0.3s ease;
  }
  
  .chevron {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .open .chevronContainer {
    transform: rotate(90deg);
  }
  
  .accordionContent {
    padding: 0 1.5rem 1rem 3.75rem;
    color: #d1d1d1;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
    .faqSection {
      min-height: 50vh !important; /* Further decrease min-height on smaller screens */
      padding: 3rem 1rem; /* Adjust padding for smaller screens */
    }
    .accordionTrigger {
      font-size: 1rem;
    }
  
    .accordionContent {
      font-size: 0.875rem;
    }
  }
  @media (max-width: 1050px) {
    .faqSection {
      min-height: 60vh !important; /* Decrease min-height on medium screens */
      padding: 4rem 1rem; /* Adjust padding for medium screens */
    }
  }
  
  @media (max-width: 1250px) {
    .faqSection {
      min-height: 60vh !important; /* Decrease min-height on medium screens */
      padding: 4rem 1rem; /* Adjust padding for medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .faqSection {
      min-height: 50vh; /* Even smaller min-height on extra small screens */
      padding: 2rem 1rem; /* Adjust padding for extra small screens */
    }
  }
  