.ApplyLoan {
    width: 100%;

    .LoanButton {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 2;

        button {
            outline: none;
            border: none;
            background-color: #AB0F0C;
            color: white;
            font-size: 18px;
            font-weight: bold;
            padding: 8px 20px;
            border-radius: 999px;
            cursor: pointer;
        }
    }

    .Banner {
        width: 100%;
        padding: 40px 0px;

        .container {
            width: 80%;
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .main-heading {
                font-size: 32px;
                font-weight: bold;
                text-align: center;
            }

            .sub-heading {
                font-size: 18px;
                max-width: 600px;
                width: 100%;
                text-align: center;
                margin: 0px auto;
            }

            .details-box {
                width: 100%;
                display: flex;
                gap: 20px;
                align-items: center;

                .left-details {
                    width: 50%;
                    padding: 20px;
                    border-radius: 20px;
                    // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .left-heading {
                        font-size: 26px;
                        font-weight: bold;
                    }

                    .left-paragraph {
                        font-size: 16px;
                    }

                    button {
                        width: fit-content;
                        border: none;
                        text-align: center;
                        padding: 10px 20px;
                        background-color: #AB0F0C;
                        color: white;
                        font-weight: 600;
                        border-radius: 8px;
                    }
                }

                .right-details {
                    width: 50%;
                    padding: 20px;
                    // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .FirstComponent {
        width: 80%;
        margin: 40px auto;

        .gridSection {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            .gridBox {
                width: 100%;
                padding: 16px;
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

                p {
                    margin-top: 8px;
                    color: #747474;
                    font-weight: 500;
                }
            }
        }

        .belowGridSection {
            margin-top: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;

            img {
                width: 50%;
                height: auto;
                object-fit: cover;
            }

            .belowGridSectionRight {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 20px;

                p {
                    color: #747474;
                    font-weight: 500;
                }

                button {
                    outline: none;
                    border: none;
                    padding: 8px 20px;
                    border-radius: 99px;
                    background-color: #AB0F0C;
                    color: white;
                    font-weight: bold;
                    font-size: 18px;
                    transition: all 500ms;
                    width: fit-content;

                    &:hover {
                        background-color: rgba(#AB0F0C, 0.5);
                    }
                }
            }
        }
    }

    .SecondComponent {
        width: 80%;
        margin: 40px auto;

        h1 {
            font-weight: 500;
            text-align: center;
        }

        .offeringsGrid {
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            .offeringsBox {
                width: 100%;
                padding: 16px;
                background-color: white;
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                text-align: center;

                img {
                    width: 80px;
                    height: auto;
                    object-fit: cover;
                }

                p {
                    color: #747474;
                    font-weight: 500;
                }
            }
        }

    }

    .ThirdComponent {
        width: 100%;
        padding: 10%;
        background-color: rgba(blue, 0.1);
        margin: 40px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .leftSide {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h1 {
                font-weight: 500;
            }

            p {
                color: rgba(black, 0.6);
                font-size: 20px;
                font-weight: 500;
            }
        }

        img {
            width: 50%;
            height: auto;
            object-fit: cover;
        }
    }

    .FourthComponent {
        width: 80%;
        margin: 40px auto;

        h1 {
            text-align: center;
        }

        .offeringsGrid {
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;

            .offeringsBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                text-align: center;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 20px;
                }

                p {
                    font-size: 18px;
                    color: #747474;
                    font-weight: 500;
                }

                button {
                    color: #AB0F0C;
                    text-decoration: underline;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

    }

    .FifthComponent {
        width: 80%;
        margin: 40px auto;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h1 {
            text-align: center;
        }

        .firstGridArray {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;

            .firstGridArrayBox {
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

                h2 {
                    color: #AB0F0C;
                    font-size: 22px;
                }
            }
        }

        .secondGridArray {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            .firstGridArrayBox {
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

                h2 {
                    color: #AB0F0C;
                    font-size: 22px;
                }
            }
        }

    }

    .SxthComponent {
        width: 80%;
        margin: 40px auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        h1 {
            text-align: center;
        }

        .eligibilityDetails {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            .eligibilityBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 20px;
                background-color: white;
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

                h2 {
                    color: #AB0F0C;
                }

                ul {
                    margin-left: 26px;
                    font-size: 20px;
                }
            }
        }
    }
}