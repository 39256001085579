// ------------- Date Style --------
.date-container {
  text-align: center;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px 0px;
  border-radius: 15px;
  box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px,
    rgba(51, 51, 51, 0.05) 0px 0px 4px;

  .jjxnOy {
    min-width: 800px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    display: block !important;

    .eCkURD {
      padding: 0px 20px !important;
    }
  }

  button {
    padding: 10px 18px;
    width: 120px;
    margin: 3px 5px;
    text-align: center;
    background-color: #AB0F0C;
    outline: none;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px,
      rgba(51, 51, 51, 0.05) 0px 0px 4px;
  }
}

// -------------------------------------------

.search-section {
  margin-top: 30px;
  position: relative;
  z-index: 2;

  .white-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 120px);
    background-color: white;
    // background-color: #f2f3f8;
    z-index: -1;
  }

  .top-section {
    width: 70%;
    height: 140px;
    margin: 0px auto;
    z-index: 5;
    padding: 20px 20px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.505);
    color: white;

    .filter-buttons {
      display: flex;
      align-items: center;
      gap: 40px;

      button {
        background-color: transparent;
        outline: none;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: center;
    margin: 0px auto;
    background-color: white;
    box-shadow: 2px 10px 8px 1px #bebebe;
    padding: 20px 20px;
    border-radius: 20px;
    position: relative;
    z-index: 5;
    margin-top: -60px;
    gap: 20px;

    .option {
      flex: 1;
      display: flex;
      overflow: hidden;

      p {
        font-weight: 500;
      }
    }

    .center {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .location {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;

        p {
          font-weight: 500;
        }

        .location-tooltip {
          width: 100px;
          position: absolute;
          top: 100%;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #cdebff;
          font-size: 13px;
          padding: 5px 0px;
          border-radius: 4px;
          transition: linear 0.3s;
        }

        .location-input {
          outline: none;
          border: none;
          font-weight: 500;
          font-size: 18px;
        }
      }

      .cal {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        cursor: pointer;
        width: 300px;

        p {
          font-weight: 500;
        }

        .date-time {
          span {
            font-size: 18px;
            border-bottom: 1px solid gray;
          }
        }

        .cal-tooltip {
          width: 140px;
          position: absolute;
          top: 100%;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #cdebff;
          font-size: 14px;
          padding: 5px 0px;
          border-radius: 4px;
          transition: linear 0.3s;
        }
      }

      .hide-tooltip {
        visibility: hidden;
        opacity: 0;
      }
    }

    .search-new-section {
      flex: 6;

      input {
        width: 100%;
        outline: none;
        border: none;
        font-size: 18px;
        font-weight: 500;
        background-color: transparent;
      }
    }

    @media screen and (max-width: 800px) {
      .search-new-section {
        width: 100%;
      }
    }

    .search-btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      color: #AB0F0C;
      margin-right: 5px;

      .s-btn {
        button {
          border: none;
          outline: none;
          color: white;
          width: 90px;
          font-weight: 600;
          padding: 8px 0px;
          border-radius: 3px;
          background-color: #AB0F0C;
          cursor: pointer;
        }
      }
    }

    .calender {
      position: absolute;
      top: 100%;
      right: 0%;
      transition: linear 0.3s;
      z-index: 8;

      .newcal {
        border-bottom: none;
        width: 400px;
        // height: 50vh;
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px,
          rgba(0, 0, 0, 0.07) 0px 0px 0px 1px !important;

        // .nice-dates-day_date{
        //   border-bottom: none;
        // }
        .nice-dates-day {
          &::before {

            background-color: #AB0F0C;
          }
        }

        .calbtn {
          text-align: center;

          button {
            width: 110px;
            margin: 10px auto;
            padding: 10px 0px;
            background-color: #AB0F0C;
            color: white;
            border: none;
            outline: none;
            border-radius: 3px;
          }
        }
      }
    }

    .hide-calender {
      visibility: hidden;
      opacity: 0;
    }
  }

  .option-menu {
    width: 68%;
    padding: 30px;
    min-height: 30%;
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px auto;
    background-color: rgb(255, 255, 255);
    z-index: 20;
    border-radius: 10px;
    transition: linear 0.3s;
    // -webkit-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.75);
    // -moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);

    .menu-container {
      display: flex;
      padding-top: 20px;

      input[type="checkbox"]:checked {
        background-color: red; // Replace with your desired color
      }
    }

    .menu-one {
      flex: 1;

      .menu-items {
        margin: 25px 0px;
        display: flex;
        align-items: center;
        gap: 5px;

        input[type="checkbox"] {
          accent-color: #AB0F0C;
          width: 16px;
          height: 16px;
        }

        span {
          font-size: 13px;
        }
      }
    }

    .menu-two {
      flex: 1;

      .menu-items {
        margin: 25px 0px;
        display: flex;
        align-items: center;
        gap: 5px;

        input[type="checkbox"] {
          accent-color: #AB0F0C;
          width: 16px;
          height: 16px;
        }

        span {
          font-size: 13px;
        }
      }
    }

    .menu-three {
      flex: 1;

      .menu-items {
        margin: 25px 0px;
        display: flex;
        align-items: center;
        gap: 5px;

        input[type="checkbox"] {
          accent-color: #AB0F0C;
          width: 16px;
          height: 16px;
        }

        span {
          font-size: 13px;
        }
      }
    }
  }

  .option-menu-hide {
    opacity: 0;
    visibility: hidden;
  }

  .bottom-section {
    width: 70%;
    margin: 20px auto;
    padding: 6px 10px;
    border-radius: 8px;
    display: flex;
    gap: 20px;
    z-index: 2;
    position: relative;
    // background-color: rgba(#000000, 0.1);

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      font-size: 18px;
      min-width: 200px;
      max-width: 200px;
    }

    .Button {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      background-color: white;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: rgba(51, 51, 51, 0.1) 0px 4px 10px, rgba(51, 51, 51, 0.05) 0px 0px 4px;
      position: absolute;
      top: 50%;
      translate: 0% -50%;
      z-index: 10;

      &:nth-child(1) {
        width: 40px;
      }
    }

    .left-Button {
      left: 240px;
    }

    .right-Button {
      right: 0;
    }

    .sliding-container {
      width: 100%;
      display: flex;
      gap: 10px;
      overflow-x: auto;
      padding: 0px 10px;
      scroll-behavior: smooth;
      position: relative;

      .location-name {
        padding: 10px 10px;
        background-color: rgba(#000000, 0.1);
        font-weight: 500;
        font-size: 18px;
        border-radius: 4px;
        white-space: nowrap;
        // color: white;
      }
    }
  }
}

.search-extra-style {
  margin-top: 40px 0px;
  z-index: 2;
}

// ------------------------- Responsive Style --------------------

@media screen and (max-width: 1500px) {
  .search-section {
    .top-section {
      width: 90%;
    }

    .container {
      width: 90%;
    }

    .bottom-section {
      width: 90%;
    }
  }
}

@media screen and (max-width: 800px) {
  .search-section {
    margin: 0px;
    padding-top: 50px;
    margin-top: -40px;

    .top-section {
      width: 95%;
    }

    .bottom-section {
      width: 95%;
      flex-direction: column;

      .left-Button{
        left: 10px;
      }

      .Button{
        translate: 0% 0%;
        bottom: 4px;
      }
    }

    .container {
      width: 95%;
      padding: 10px 10px;

      .center {
        width: 40%;

        .location {
          .myLocationOutlinedIcon {
            display: none;
          }
        }
      }

      .calender {
        .newcal {
          width: 260px;
        }
      }

      .option {
        display: none;
      }

      .location {
        .location-tooltip {
          visibility: hidden;
          opacity: 0;
        }
      }

      .cal {
        .date-time {
          span {
            font-size: 14px;
          }
        }

        .cal-tooltip {
          visibility: hidden;
          opacity: 0;
        }
      }

      .search-btn {
        gap: 5px;
        width: 100px;

        .s-btn {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }

      .calender {
        width: 80%;
        top: 100%;
        right: 10%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .search-section {
    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-left: 20px;

      .center {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        display: none;

        .cal {
          width: 100%;
          justify-content: space-between;
          padding-right: 20px;
          gap: 30px;
        }
      }
    }
  }

  .date-container {
    .jjxnOy {
      // width: 350px !important;
      min-width: 30% !important;
      display: flex !important;
      flex-direction: column !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .search-section {
    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-left: 20px;

      .center {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
      }
    }
  }
}