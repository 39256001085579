.LoanCalculator {
    width: 80%;
    margin: auto;

    .mainHeading {
        font-weight: bold;
        font-size: 22px;
        margin-top: 10px;
    }

    .container {
        width: 100%;
        margin-top: 10px;
        background-color: rgba(#AB0F0C, 0.1);
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 10px;
        border-radius: 8px;
        font-size: 16px;
    }

    .FirstComponent {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 40px 0px;

        .leftSide{
            width: 50%;

            .box{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 12px;

                .boxDetails{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .value-box {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        border: 1px solid gray;
                        padding: 10px 16px;
                        border-radius: 4px;

                        input{
                            outline: none;
                            border: none;
                            width: fit-content;
                            max-width: 100px;
                        }
                    }
                }
            }
        }

        .rightSide {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .right-left {}

            .right-right {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .infobox {
                    width: 260px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    justify-content: space-between;

                    .subInfoBox{
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }
                }
            }
        }
    }

    .SecondComponent {
        width: 100%;

        .subHeading {
            font-weight: 600;
            font-size: 18px;
            margin: 10px 0px;
        }
    }

    .ThirdComponent {
        width: 100%;

        .subHeading {
            font-weight: 600;
            font-size: 18px;
            margin: 10px 0px;
        }
    }

    .FourthComponent {
        width: 100%;

        .subHeading {
            font-weight: 600;
            font-size: 18px;
            margin: 10px 0px;
        }
    }

    .FifthComponent {
        width: 100%;

        .subHeading {
            font-weight: 600;
            font-size: 18px;
            margin: 10px 0px;
        }
    }
}