.testimonialSection {
    padding: 4rem 1rem;
    background-color: white;
  }
  
  .testimonialContainer {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
  
  .profileImages {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .profileButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.5;
    transform: scale(0.6);
  }
  
  .profileButton.active {
    opacity: 1;
    transform: scale(1);
  }
  
  .profileImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .testimonialContent {
    max-width: 800px;
    text-align: center;
    transition: opacity 0.5s ease;
  }
  
  .fadeIn {
    opacity: 1;
  }
  
  .fadeOut {
    opacity: 0;
  }
  
  .quote {
    font-size: 1.75rem;
    line-height: 1.5;
    color: #6B7280;
    margin-bottom: 2rem;
    font-weight: 400;
  }
  
  .author {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
  }
  
  .company {
    font-size: 1rem;
    color: #6B7280;
  }
  
  @media (max-width: 768px) {
    .testimonialSection {
      padding: 3rem 1rem;
    }
  
    .quote {
      font-size: 1.25rem;
    }
  
    .profileImage {
      width: 80px;
      height: 80px;
    }
  
    .profileImages {
      gap: 0.5rem;
    }
  }
  
  