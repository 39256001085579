/* partners.css */

/* Main section styles */
.partners-section {
  background: linear-gradient(55deg, #004d40 0%, #001524 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
}

.partner-inside {
  width: 100%;
  margin-top: 4rem;
}

.partners-container {
  width: 100%;
}

/* Heading styles */
.partners-heading {
  color: white;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem;
  line-height: 1.2;
}

.partnerships-line {
  position: relative;
  display: inline-block;
}

.underline-img {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
}

/* Partners grid and rows */
.partners-grid {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  overflow: hidden;
}

.partner-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: max-content;
  padding: 1rem 0;
}

/* Logo container and logo styles */
.partner-logo-container {
  flex-shrink: 0;
  /* Fixed dimensions for logo container */
  height: 60px;  /* Fixed height */
  width: 180px; /* Fixed width */
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
}

.partner-logo-container:hover {
  background-color: rgba(255, 255, 255, 1);
}

.partner-logo {
  height: 100%;
  width: 100%;
  object-fit: contain; /* Maintains aspect ratio within fixed container */
  max-width: 100%;
  max-height: 100%;
}

/* Animation keyframes */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Animation classes */
.scroll-left {
  animation: scroll-left 20s linear infinite;
}

.scroll-right {
  animation: scroll-right 20s linear infinite;
}

/* Pause animation on hover */
.scroll-left:hover,
.scroll-right:hover {
  animation-play-state: paused;
}

/* Responsive styles */
@media (max-width: 768px) {
  .partners-heading {
    font-size: 2.5rem;
  }

  .partner-row {
    gap: 1.5rem;
  }

  .partner-logo-container {
    height: 50px;  /* Slightly smaller on tablets */
    width: 150px;
  }
}

@media (max-width: 480px) {
  .partners-heading {
    font-size: 2rem;
  }

  .partner-logo-container {
    height: 40px;  /* Even smaller on mobile */
    width: 120px;
  }

  .partners-grid {
    gap: 3rem;
  }
}
@media (max-width: 1024px) {
  .partners-section {
    min-height: 80vh; /* Decrease min-height on medium screens */
  }
}

@media (max-width: 768px) {
  .partners-section {
    min-height: 60vh; /* Further decrease min-height on smaller screens */
  }
}

@media (max-width: 480px) {
  .partners-section {
    min-height: 50vh; /* Even smaller min-height on extra small screens */
  }
}