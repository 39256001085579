.container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 50px;
    height: 60vh;
    display: flex; /* Enables Flexbox */
  flex-direction: column; /* Align items in a column */
  justify-content: center; /* Centers items vertically */
  align-items: center; /* Centers items horizontally */
  text-align: center;
  }
  
  .subtitle {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  
  .button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #333;
  }
  
  .time {
    font-size: 1rem;
    color: #555;
  }
  
  .clock {
    font-weight: bold;
    color: #000;
  }
  