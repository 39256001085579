.CommercialBooking {
    width: 100%;
    overflow: hidden;

    .Banner {
        width: 100%;
        min-height: 88vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2pc;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        h1 {
            color: white;
            font-size: 4.5rem;
            font-weight: 600;
            margin: 0px auto;
            width: 80%;
        }

        .paragraphOne {
            font-size: 24px;
            color: white;
            margin: 0px auto;
            width: 80%;
        }

        @media screen and (max-width: 767px) {

            .paragraphOne {
                font-size: 20px;
            }
        }
    }

    .FirstComponent {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;

        .headings{
            width: 80%;
            margin: auto;
            margin-bottom: 20px;

            h2{
                font-weight: 600;
                font-size: 52px;
            }

            p{
                font-size: 18px;
                margin-top: 16px;
            }

            @media screen and (max-width: 1680px){
                h2{
                    font-size: 52px;
                }

                p{
                    font-size: 18px;
                }
            }

            @media screen and (max-width: 1440px){
                h2{
                    font-size: 50px;
                }
                p{
                    font-size: 17px;
                }
            }

            @media screen and (max-width: 1280px){
                h2{
                    font-size: 46px;
                }
                p{
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1200px){
                h2{
                    font-size: 40px;
                }
                p{
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 1024px){
                h2{
                    font-size: 36px;
                }
                p{
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 912px){
                h2{
                    font-size: 32px;
                }
            }

            @media screen and (max-width: 650px){
                h2{
                    font-size: 22px;
                }
            }
        }

        .Container {
            height: fit-content;
            width: 80%;
            margin: auto;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #AB0F0C;
            display: flex;
            box-sizing: border-box;

            .right-container {
                padding: 50px;
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .contents {
                    width: 100%;
                    height: fit-content;
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    gap: 26px;

                    img{
                        width: 60px;
                    }

                    .Heading{
                        font-weight: 600;
                        font-size: 32px;
                    }

                    .Paragraph{
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
            }

            .left-container{
                box-sizing: border-box;
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 100px;
                padding-bottom: 80px;
                width: 50%;
                height: 100%;
                // background-color: #AB0F0C;
                // background: rgb(200,98,70);
// background-image: radial-gradient(circle, rgba(200,98,70,0.9472163865546218) 0%, rgba(171,15,12,1) 48%, rgba(255,46,0,0.9808298319327731),100%);
background: linear-gradient(90deg, rgba(171, 15, 12, 1) 43%, rgba(184, 38, 9, 0.9808298319327731) 81%, rgba(200, 98, 70, 0.9472163865546218) 100%);

                .list-container{
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: rgba(255, 255, 255, 0.497);
                        border-radius: 5px;
                      }
    
                      &::-webkit-scrollbar-thumb {
                        background: white; /* Color of the thumb */
                        border-radius: 5px; /* Rounded corners of the thumb */
                      }

                    .box{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        cursor: pointer;

                        .heading{
                            color: white;
                            font-size: 20px;
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }

                        .box-bottom{
                            width: calc(100% - 60px);
                            margin-left: 30px;
                            height: 1px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .CommercialBooking{
        .Banner {

            h1 {
                width: 90%;
            }

            .paragraphOne {
                width: 90%;
            }
        }

        .FirstComponent{
            .headings{
                width: 90%;
            }
            .Container{
                width: 90%;
                flex-direction: column-reverse;
                height: fit-content;

                .left-container{
                    width: 100%;
                }

                .right-container{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .CommercialBooking{
        .Banner {

            h1 {
                font-size: 32px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .CommercialBooking{
        .Banner {

            h1 {
                font-size: 26px;
            }
        }
    }
}