// Breakpoint mixins for easy responsive design
@mixin mobile {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 769px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

.car-rental {
    min-height: 100vh;
    background-image: url('https://www.mahindratractor.com/sites/default/files/styles/product_spotlight_1366x360_/public/2023-08/all_tractors_desktop_0.webp?itok=bDJpKrPr');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; // Keeps the background in place on desktop.
    color: white;
    font-family: 'Inter', system-ui, -apple-system, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    @include mobile {
        background-attachment: scroll; // Optimizes performance on mobile.
        background-size: auto; // Adjust for smaller screens.
        min-height: 100%;
        padding: 0;
    }

    @include tablet {
        background-size: cover;
        background-position: top center; // Better alignment for mid-sized screens.
    }
}

.container-inside {
    background-image: url('https://www.mahindratractor.com/sites/default/files/styles/product_spotlight_1366x360_/public/2023-08/all_tractors_desktop_0.webp?itok=bDJpKrPr');
    background-size: cover;
    background-position: center;
    backdrop-filter: blur(10px);
    height: 90vh;
    width: 95%;
    max-width: 1200px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;


    @include mobile {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        padding: 1rem;
        background-position: top; // Optimize for smaller height on mobile.
    }

    @include tablet {
        width: 98%;
        height: auto;
        min-height: 80vh; // Adjust for mid-sized screens.
    }
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @include mobile {
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
    }

    .logo {
        font-size: 1.75rem;
        font-weight: bold;
        color: #00CA8E;

        @include mobile {
            margin-bottom: 1rem;
        }
    }

    .menu-items {
        display: flex;
        gap: 2rem;

        @include mobile {
            display: none;
        }

        a {
            color: white;
            text-decoration: none;
            opacity: 0.7;
            transition: all 0.3s ease;
            position: relative;

            &:hover, &.active {
                opacity: 1;
                
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #00CA8E;
                }
            }
        }
    }

    .search-container {
        display: flex;
        align-items: center;
        gap: 1rem;

        @include mobile {
            width: 100%;
            justify-content: center;
        }
    }
}

.search-box {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 0.5rem 1rem;

    @include mobile {
        width: 100%;
        max-width: 300px;
    }

    .search-icon {
        opacity: 0.7;
        margin-right: 0.75rem;
    }

    input {
        background: transparent;
        border: none;
        color: white;
        outline: none;
        width: 200px;

        @include mobile {
            width: 100%;
        }

        &::placeholder {
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.user-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 0.75rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    @include mobile {
        justify-content: flex-start;
        padding-top: 1rem;
    }
}

.content {
    max-width: 600px;

    @include mobile {
        max-width: 100%;
        text-align: center;
    }

    h1 {
        width: 100%;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        color: #00CA8E;

        @include mobile {
            font-size: 1.5rem;
        }
    }

    p {
        font-size: 1.1rem;
        opacity: 0.8;
        margin-bottom: 2rem;

        @include mobile {
            font-size: 1rem;
        }
    }
}

.booking-form {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 15px;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include mobile {
        padding: 1.5rem;
        gap: 1rem;
    }

    .toggle-buttons {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        justify-content: center;

        @include mobile {
            gap: 0.5rem;
        }

        button {
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 30px;
            color: white;
            padding: 0.5rem 1.5rem;
            cursor: pointer;
            transition: all 0.3s ease;

            @include mobile {
                padding: 0.4rem 1rem;
                font-size: 0.9rem;
            }

            &.active, &:hover {
                background: #00CA8E;
                border-color: #00CA8E;
            }
        }
    }

    .input-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        @include mobile {
            grid-template-columns: 1fr;
        }

        .input-wrapper {
            position: relative;
            display: flex;
            align-items: center;

            .input-icon {
                position: absolute;
                left: 15px;
                opacity: 0.7;

                @include mobile {
                    left: 10px;
                }
            }

            input {
                width: 100%;
                background: rgba(255, 255, 255, 0.1);
                border: none;
                border-radius: 10px;
                color: white;
                padding: 15px 15px 15px 45px;
                outline: none;

                @include mobile {
                    padding: 12px 12px 12px 40px;
                    font-size: 0.9rem;
                }

                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }
    }
}

.search-button {
    background: #00CA8E;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 1rem;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @include mobile {
        padding: 0.75rem;
        font-size: 0.9rem;
    }

    &:hover {
        background: #00b77e;
    }
}

.progress-indicator {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;
    opacity: 0.7;

    @include mobile {
        justify-content: center;
        margin-top: 1rem;
        font-size: 0.8rem;
    }
}

// Additional responsive adjustments
@include mobile {
    body, html {
        overflow-x: hidden;
    }
}